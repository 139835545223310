import React, { useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination } from "swiper";
import yarindrory from "../../../../media/barbers/drory-image.png";
import barber3 from "../../../../media/barbers/barber3.png";
import barber4 from "../../../../media/barbers/barber4.png";
import {
  BookingContext,
  StagesInterface,
  stagesStrings,
} from "../BookingContainer";
import fadeInLeft from "react-animations/lib/fadeInLeft";

// Animations START

const fadeInAnimation = keyframes`${fadeInLeft}`;

// Animation END
// Styled Components START

const BarberPickStyled = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 40px;
`;

export const HeaderText = styled.a`
  color: #fff;
  font-size: 24px;
  font-family: hebrewExtraBold;
  text-shadow: -5px 5px 0px #3b82e096, -5px 15px 0px #3b82e025;
  animation: 1s ${fadeInAnimation};
`;

export const SwiperDivStyled = styled.div`
  animation: 0.5s ${fadeInAnimation};
`;

const SwiperStyled = styled(Swiper)`
  width: 300px;
  height: 100%;
  img {
    width: 250px;
    height: 100%;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -30px !important;
  }

  .swiper-pagination-bullet {
    background-color: #1c9fff !important;
    width: 10px !important;
    height: 10px !important;
  }
`;

// Styled Components END
// Project START

const BarberPickStage = () => {
  const _BookingContext = useContext(BookingContext);

  const swiperSlideStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleBarberPick = (stage: StagesInterface, barberPick: string) => {
    _BookingContext?.setBarberPick(barberPick);
    _BookingContext?.setStage(stage);
  };

  useEffect(() => {
    _BookingContext?.setStage({
      num: stagesStrings[0].num,
      str: stagesStrings[0].str,
    });
    _BookingContext?.setProgress(0);
    _BookingContext.setLoading(false);
  }, []);

  return (
    <BarberPickStyled>
      <HeaderText>בחר את הספר שלך</HeaderText>
      <SwiperDivStyled>
        <SwiperStyled
          dir={"ltr"}
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards, Pagination]}
          className="barberSwiper"
          pagination={{ clickable: true }}
        >
          <SwiperSlide style={swiperSlideStyle}>
            <img
              src={yarindrory}
              alt={"Yarin Drory"}
              onClick={() =>
                handleBarberPick(
                  {
                    num: stagesStrings[1].num,
                    str: stagesStrings[1].str,
                  },
                  "Drory"
                )
              }
            />
          </SwiperSlide>
          <SwiperSlide style={swiperSlideStyle}>
            <img
              src={barber3}
              alt={"Other Barber"}
              onClick={() =>
                handleBarberPick(
                  {
                    num: stagesStrings[1].num,
                    str: stagesStrings[1].str,
                  },
                  "Barber3"
                )
              }
            />
          </SwiperSlide>
            <SwiperSlide style={swiperSlideStyle}>
                <img
                    src={barber4}
                    alt={"Other Barber"}
                    onClick={() =>
                        handleBarberPick(
                            {
                                num: stagesStrings[1].num,
                                str: stagesStrings[1].str,
                            },
                            "Barber4"
                        )
                    }
                />
            </SwiperSlide>
        </SwiperStyled>
      </SwiperDivStyled>
    </BarberPickStyled>
  );
};

export default BarberPickStage;
