import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import styled, { keyframes } from "styled-components";
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
// Components Import
import NavContainer from "./components/Navigation/NavContainer";
import BookingContainer from "./components/Pages/BookingPage/BookingContainer";
import HomeContainer from "./components/Pages/HomeContainer";
import { DeleteContainer } from "./components/Pages/DeleteContainer";
import fadeIn from "react-animations/lib/fadeIn";
import Loader from "./components/Utils/Loader";
import {
  AdminContainer,
  ModalInterface,
} from "./components/Pages/AdminPage/AdminContainer";
import { getAuth } from "firebase/auth";
import { AuthProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { app } from "./backend/firebase";
import { getFirestore } from "firebase/firestore";
import { ErrorModal } from "./components/Utils/ErrorModal";
import { SuccessModal } from "./components/Utils/SuccessModal";

// Animations START

const fadeInAnimation = keyframes`${fadeIn}`;

// Styled-Components START

const AppContainerStyled = styled.div`
  width: 100%;
  background-color: #000;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const AnimWrapper = styled.div`
  animation: 1s ${fadeInAnimation};
`;

// Styled-Components END

// Interfaces & Types START

export interface MainContextInterface {
  currentPage: string;
  setCurrentPage: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setErrorModalState: Dispatch<SetStateAction<ModalInterface>>;
  setSuccessModalState: Dispatch<SetStateAction<ModalInterface>>;
}

// Interfaces & Types END

export const AppMainContext = createContext<MainContextInterface | undefined>(
  undefined
);

const App = () => {
  const [currentPage, setCurrentPage] = useState<string>("Home");
  const [loading, setLoading] = useState<boolean>(true);
  const [errorModalState, setErrorModalState] = useState<ModalInterface>({
    show: false,
  });
  const [successModalState, setSuccessModalState] = useState<ModalInterface>({
    show: false,
  });
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <Router>
      <AppMainContext.Provider
        value={{
          currentPage,
          setCurrentPage,
          setLoading,
          setSuccessModalState,
          setErrorModalState,
        }}
      >
        <AppContainerStyled>
          {currentPage !== "Admin" ? <NavContainer /> : null}
          <ErrorModal
            modalState={errorModalState.show}
            errorText={errorModalState?.text}
            setModalState={setErrorModalState}
          />
          <SuccessModal
            modalState={successModalState.show}
            successText={successModalState.text}
            setModalState={setSuccessModalState}
          />
          <Routes>
            <Route path="/" element={<HomeContainer />} />
            <Route
              path="/book"
              element={
                <AnimWrapper>
                  <BookingContainer />
                </AnimWrapper>
              }
            />
            <Route
              path="/delete"
              element={
                <AnimWrapper>
                  <DeleteContainer />
                </AnimWrapper>
              }
            />
            <Route
              path="/admin/*"
              element={
                <AuthProvider sdk={auth}>
                  <FirestoreProvider sdk={firestoreInstance}>
                    <AdminContainer
                      setLoading={setLoading}
                      setCurrentPage={setCurrentPage}
                    />
                  </FirestoreProvider>
                </AuthProvider>
              }
            />
          </Routes>
        </AppContainerStyled>
      </AppMainContext.Provider>
      <Loader display={loading} />
    </Router>
  );
};

export default App;
