import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { AdminColors } from "../_Utils/colors";
import { AdminContext } from "../AdminContainer";
import Select, { SingleValue } from "react-select";

const SecondTopBarView = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  box-shadow: 0 0 15px #959595;
`;

const TopTextView = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: column;

  a {
    color: #545454 !important;
    font-family: hebrewLight;
    font-size: 14px;
  }
`;

const HourDiv = styled.div`
  text-align: right;
  width: 100%;
  padding-right: 15px;
`;

const DateDiv = styled.div`
  text-align: left;
  width: 100%;
  padding-left: 15px;
`;

const SecondHeader = styled.h1`
  color: #6a6a6a;
  font-family: hebrewRegular;
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
`;

const SignOutDiv = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  padding-right: 13px;

  Button {
    padding: 0 2px;
    border: 0;
    color: ${AdminColors.NAV_BLUE};
  }
`;

const BarberPicker = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
  padding-left: 13px;
`;

interface BarberPickTypes {
  value: string;
  label: string;
}

interface Props {
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
  signOutHook: any;
}

interface DateHookInterface {
  dayNumber: string;
  monthNumber: string;
  monthWithoutZero: number;
  yearNumber: number;
  fullDisplayDate: string;
  fullFirebaseDate: string;
  dayOfWeek: number;
  dayEnglish: string;
  dayHebrew: string;
  hour: string;
}

export const englishDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const hebrewDays = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];
export const hebrewMonths = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

export const UseDateHooks = (date?: Date): DateHookInterface => {
  const _date = date ?? new Date();
  const monthString = (): string => {
    if ((parseInt(String(_date.getMonth())) + 1).toString().length === 1)
      return `0${parseInt(String(_date.getMonth())) + 1}`;
    return (parseInt(String(_date.getMonth())) + 1).toString();
  };
  const dayString = (): string => {
    if (parseInt(String(_date.getDate())).toString().length === 1)
      return `0${parseInt(String(_date.getDate()))}`;
    return parseInt(String(_date.getDate())).toString();
  };
  const hourString = (): string => {
    const hrs: string =
      _date.getHours() < 10
        ? `0${_date.getHours().toString()}`
        : _date.getHours().toString();
    const mins: string =
      _date.getMinutes() < 10
        ? `0${_date.getMinutes().toString()}`
        : _date.getMinutes().toString();
    return `${hrs}:${mins}`;
  };

  const monthWithoutZero = parseInt(String(_date.getMonth())) + 1;
  return {
    dayNumber: dayString(),
    monthNumber: monthString(),
    monthWithoutZero: monthWithoutZero,
    yearNumber: _date.getFullYear(),
    fullDisplayDate: `${dayString()}/${monthString()}/${_date.getFullYear()}`,
    fullFirebaseDate: `${dayString()}-${monthString()}-${_date.getFullYear()}`,
    dayOfWeek: _date.getDay(),
    dayEnglish: englishDays[_date.getDay()],
    dayHebrew: hebrewDays[_date.getDay()],
    hour: hourString(),
  };
};

export const SecondTopBar = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [hour, setHour] = useState<string>("00:00:00");
  const [date, setDate] = useState<string>("00/00/0000");

  const setHourHook = () => {
    const date = new Date();
    setHour(
      ("0" + date.getHours()).substr(-2) +
        ":" +
        ("0" + date.getMinutes()).substr(-2) +
        ":" +
        ("0" + date.getSeconds()).substr(-2)
    );
  };

  const adminsList: BarberPickTypes[] = [
    {
      value: "Drory",
      label: "דרורי",
    },
    {
      value: "Barber3",
      label: "עילאי",
    },
    {
      value: "Barber4",
      label: "נאור",
    },
  ];

  const handleBarberSelection = (e) => {
    _AdminContext.setAppointmentsAdminName(e.value);
  };

  useEffect(() => {
    setHourHook();
    setInterval(() => {
      setHourHook();
    }, 1000);
    setDate(UseDateHooks().fullDisplayDate);
  }, []);
  return (
    <SecondTopBarView>
      <TopTextView>
        <HourDiv>
          <a>{hour}</a>
        </HourDiv>
        <DateDiv>
          <a>{date}</a>
        </DateDiv>
      </TopTextView>
      <SecondHeader>ברוך הבא</SecondHeader>
      <SignOutDiv>
        <Button variant="light" onClick={props.signOutHook}>
          התנתק
        </Button>
      </SignOutDiv>
      {_AdminContext.adminName === "Drory" ? (
        <BarberPicker>
          <Select
            defaultValue={"דרורי"}
            placeholder={"בחר ספר"}
            onChange={(e) => handleBarberSelection(e)}
            // @ts-ignore
            options={adminsList}
          />
        </BarberPicker>
      ) : null}
    </SecondTopBarView>
  );
};
