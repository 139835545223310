import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AdminColors } from "../../_Utils/colors";
import { Button } from "react-bootstrap";
import { AdminContext } from "../../AdminContainer";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../backend/firebase";

const AppointmentTypeView = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: max-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;
  margin: 20px 0;

  &:first-child {
    margin: 0 0 20px 0;
  }
`;

const InnerContainer = styled.div`
  width: 200px;
  text-align: center;
`;

const HeaderText = styled.h3`
  font-size: 20px;
  font-family: hebrewRegular;
`;

const HaircutItemContainer = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: row;

  input {
    appearance: none;
    background-color: #eaeaea;
    border: none;
    border-radius: 5px;
    color: ${AdminColors.NAV_BLUE};
    font-size: 16px;
    font-family: hebrewLight;
    text-align: center;
    padding: 2.5px 0;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }
  }
`;

const InputHeaders = styled.a`
  color: #000;
  font-size: 16px;
  font-family: hebrewLight;
`;

const SaveButton = styled(Button)`
  margin-top: 15px;
  padding: 5px 0;
  width: 100%;

  a {
    color: #fff;
    font-family: hebrewLight;
    font-size: 20px;
  }
`;

interface Props {
  haircutName: string;
  haircutDuration: number;
  haircutCost: string;
  haircutIndex: number;
}

export const Appointment_type = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [_name, setName] = useState<string>(props.haircutName ?? "אין מידע");
  const [_duration, setDuration] = useState<number>(props.haircutDuration ?? 0);
  const [_cost, setCost] = useState<string>(props.haircutCost ?? "אין מידע");

  const UpdateDatabaseHook = async () => {
    _AdminContext.setIsLoading(true);
    const docRef = doc(db, `Barbers/${_AdminContext.adminName}/Data`, "Type");
    await updateDoc(docRef, {
      [`Option${props.haircutIndex}`]: [_name, _duration, _cost],
    })
      .then(() => {
        _AdminContext.setIsLoading(false);
        _AdminContext.setSuccessModalState({
          show: true,
        });
      })
      .catch(() => {
        _AdminContext.setIsLoading(false);
        _AdminContext.setErrorModalState({
          show: true,
          text: "קרתה תקלה בעת השמירה, נסה שוב",
        });
      });
  };

  return (
    <AppointmentTypeView>
      <InnerContainer>
        <HeaderText>{`תספורת #${props.haircutIndex + 1}`}</HeaderText>
        <HaircutItemContainer>
          <InputHeaders>סוג התספורת</InputHeaders>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={_name}
          />
        </HaircutItemContainer>
        <HaircutItemContainer>
          <InputHeaders>זמן התספורת (בדקות)</InputHeaders>
          <input
            type="text"
            onChange={(e) => setDuration(parseInt(e.target.value))}
            value={_duration}
          />
        </HaircutItemContainer>
        <HaircutItemContainer>
          <InputHeaders>עלות התספורת (₪)</InputHeaders>
          <input
            type="tel"
            onChange={(e) => setCost(e.target.value)}
            value={_cost}
          />
        </HaircutItemContainer>
        <SaveButton onClick={UpdateDatabaseHook}>
          <a>שמור</a>
        </SaveButton>
      </InnerContainer>
    </AppointmentTypeView>
  );
};
