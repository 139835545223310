import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Button, Form } from "react-bootstrap";
import fadeInLeft from "react-animations/lib/fadeInLeft";
import { AdminColors } from "../_Utils/colors";
import Div100vh from "react-div-100vh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons/faUserLock";
import { auth } from "../../../../backend/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { AdminContext } from "../AdminContainer";

const fadeInAnimation = keyframes`${fadeInLeft}`;

const AdminLoginView = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  height: 100%;
  color: #000;
  background-color: ${AdminColors.BG_WHITE};
`;

const FormStyled = styled(Form)`
  animation: 0.5s ${fadeInAnimation};

  .form-control {
    border: 0 !important;
    box-shadow: 0 0 15px #e6e6e6;
  }
`;

const ButtomStyled = styled(Button)`
  width: 100%;
  margin-top: 5px;
`;

const ConfirmationTextDiv = styled.div`
  padding-bottom: 10px;
  display: grid;
  place-items: center;
  grid-auto-flow: column;

  h1 {
    color: #262626;
    font-size: 30px;
    font-family: hebrewLight;
    text-align: center;
    margin: 0;
  }

  path {
    color: #000 !important;
  }

  #userIcon {
    font-size: 30px;
  }
`;

const SubmitStyled = styled(Button)`
  width: 100%;
`;

interface Props {
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const AdminLogin = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [phoneInputState, setPhoneInputState] = useState<boolean>(false);
  const [codeInputState, setCodeInputState] = useState<boolean>(true);
  const [smsBtnState, setSmsBtnState] = useState<boolean>(true);
  const [verifyBtnState, setVerifyBtnState] = useState<boolean>(true);
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined);

  const handleInputChange = (e, characters: number) => {
    e.target.value.length !== characters
      ? setSmsBtnState(true)
      : setSmsBtnState(false);
  };

  const handleSubmit = async (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    if (!isRendered) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: () => setIsRendered(true),
        },
        auth
      );
    }
    const phoneNumber: string = `+972${e.target[0].value}`;
    await signInWithPhoneNumber(
      auth,
      phoneNumber,
      (window as any).recaptchaVerifier
    )
      .then((result) => {
        setConfirmationResult(result);
        setPhoneInputState(true);
        setCodeInputState(false);
        setSmsBtnState(true);
        setVerifyBtnState(false);
        props.setIsLoading(false);
      })
      .catch(() => {
        _AdminContext.setErrorModalState({ show: true });
      });
  };

  const handleSignIn = (e, confirmationResult) => {
    props.setIsLoading(true);
    e.preventDefault();
    const code = e.target[1].value;
    confirmationResult
      .confirm(code)
      .then(() => {
        props.setIsLoading(true);
        props.setIsLogged(true);
      })
      .catch(() => {
        props.setIsLoading(false);
        _AdminContext.setErrorModalState({ show: true, text: "הוזן קוד שגוי" });
      });
  };

  return (
    <Div100vh>
      <AdminLoginView>
        <FormStyled
          onSubmit={(e) =>
            !smsBtnState ? handleSubmit(e) : handleSignIn(e, confirmationResult)
          }
          validated={true}
        >
          <ConfirmationTextDiv>
            <h1>כניסת מנהל</h1>
            <FontAwesomeIcon icon={faUserLock} id="userIcon" />
          </ConfirmationTextDiv>
          <Form.Group className="mb-2" controlId="formPhone">
            <Form.Control
              type="tel"
              placeholder="הכנס מס׳ פלאפון"
              disabled={phoneInputState}
              onChange={(e) => handleInputChange(e, 10)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formCode">
            <Form.Control
              type="tel"
              placeholder="הכנס קוד"
              disabled={codeInputState}
              onChange={(e) => handleInputChange(e, 10)}
              required
            />
          </Form.Group>
          <div id="sign-in-button" />
          <SubmitStyled
            variant="primary"
            type="submit"
            id="signInButton"
            disabled={smsBtnState}
          >
            שלח סמס
          </SubmitStyled>
          <ButtomStyled disabled={verifyBtnState} type="submit">
            אמת קוד
          </ButtomStyled>
        </FormStyled>
      </AdminLoginView>
    </Div100vh>
  );
};
