import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  InnerContainer,
  RightSide,
} from "../AdminPage/_Pages/_Parts/Vacations_Item";
import { functions } from "../../../backend/firebase";
import { AppMainContext } from "../../../App";
import { httpsCallable } from "firebase/functions";

const DeleteItemView = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: max-content;
  padding: 5px;
  border-radius: 10px;
  margin: 5px 0;
  background-color: #e6e6e6;
  color: #000;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #9f9f9f, 0 5px 0 0 #6b6b6b, 0 10px 5px 0 #343434;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
`;

interface Props {
  date: string;
  hour: string;
  uid: string;
  setShouldLoad: Dispatch<SetStateAction<boolean>>;
  phoneNumber: string;
}

export const DeleteItem = (props: Props) => {
  const _AppMainContext = useContext(AppMainContext);
  const hourFinal = Object.keys(props.hour)[0];
  const barberFinal = Object.values(props.hour)[0];
  const hourNumber = parseInt(Object.keys(props.hour)[0].replace(":", ""));
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleDelete = async () => {
    if (!isDisabled) {
      props.setShouldLoad(true);
      await httpsCallable(
        functions,
        "deleteAppointmentFunction"
      )({
        barberName: barberFinal,
        date: props.date,
        appointmentHour: hourNumber,
        hourBeautified: hourFinal,
        bookUserID: props.uid,
        phoneNumber: props.phoneNumber,
      })
        .then(() => {
          setIsDisabled(true);
          _AppMainContext?.setSuccessModalState({ show: true });
          props.setShouldLoad(false);
        })
        .catch((e) => {
          props.setShouldLoad(false);
          _AppMainContext?.setErrorModalState({
            show: true,
            text: `קוד ${e} : קרתה תקלה בעת המחיקה, נסה שוב`,
          });
        });
    }
  };
  return (
    <DeleteItemView disabled={isDisabled}>
      <InnerContainer>
        <RightSide>{`בתאריך ${props.date} בשעה ${hourFinal}`}</RightSide>
        <div>
          <DeleteForeverIcon color={"error"} onClick={handleDelete} />
        </div>
      </InnerContainer>
    </DeleteItemView>
  );
};
