import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import {
  BoxStyled,
  CloseButtonStyled,
  HeaderSection,
  ModalHeader,
  ModalStyled,
} from "./AppointmentCreation";
import { Button, Form } from "react-bootstrap";
import { LoaderInner } from "./LoaderInner";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../backend/firebase";
import { AdminContext } from "../AdminContainer";

const UserCreationView = styled.div``;

interface Props {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
}

export const UserCreation = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);
  const [phoneChosen, setPhoneChosen] = useState<string>("");
  const [nameChosen, setNameChosen] = useState<string>("");

  const handleSubmit = async (e) => {
    setShowInnerLoader(true);
    e.preventDefault();
    e.stopPropagation();
    if (phoneChosen?.length !== 10 || phoneChosen[0] !== "0") {
      setShowInnerLoader(false);
      _AdminContext.setErrorModalState({
        show: true,
        text: "מספר טלפון לא תקין",
      });
    } else {
      await callUserCreationHook();
    }
  };

  const callUserCreationHook = async () => {
    await httpsCallable(
      functions,
      "createUserFunction"
    )({
      userPhone: phoneChosen,
      userName: nameChosen,
    })
      .then((res) => {
        setShowInnerLoader(false);
        console.log(res);
        // @ts-ignore
        if (res.data && res.data.code === "303") {
          _AdminContext.setErrorModalState({
            show: true,
            text: "שגיאה - המשתמש כבר קיים",
          });
        } else {
          _AdminContext.setSuccessModalState({
            show: true,
            text: "המשתמש נוצר בהצלחה",
          });
        }
      })
      .catch((error) => {
        setShowInnerLoader(false);
        if (error.message === "304") {
          _AdminContext.setErrorModalState({
            show: true,
            text: `המשתמש כבר קיים במערכת`,
          });
        } else {
          _AdminContext.setErrorModalState({
            show: true,
            text: `קוד - ${error.message} קרתה תרצה ביצירת המשתמש.`,
          });
        }
      });
  };

  return (
    <UserCreationView>
      <ModalStyled
        open={props.modalState}
        onClose={() => props.setModalState(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxStyled>
          <Form onSubmit={(e) => handleSubmit(e)} validated>
            <HeaderSection>
              <ModalHeader>יצירת משתמש</ModalHeader>
              <CloseButtonStyled onClick={() => props.setModalState(false)} />
            </HeaderSection>
            <input
              placeholder={"שם מלא"}
              required
              onChange={(e) => setNameChosen(e.target.value)}
            />
            <input
              placeholder={"מספר טלפון"}
              required
              onChange={(e) => setPhoneChosen(e.target.value)}
            />
            <Button type={"submit"}>צור משתמש</Button>
          </Form>
          {showInnerLoader ? <LoaderInner /> : null}
        </BoxStyled>
      </ModalStyled>
    </UserCreationView>
  );
};
