import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { beautifyHourHook } from "./Appointment_Item";
import { Button, Form } from "react-bootstrap";
import { UseCreateHours } from "../../_Utils/AppointmentCreation";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../backend/firebase";
import { AdminContext } from "../../AdminContainer";
import { englishDays, hebrewDays } from "../../SecondTopBar/SecondTopBar";

const OpenHoursContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  min-width: 250px;
  width: 100%;
  height: max-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;
  margin: 10px 0;
`;

const SaveButton = styled(Button)`
  margin-top: 10px;
  padding: 5px 0;
  width: 100%;

  a {
    color: #fff;
    font-family: hebrewLight;
    font-size: 20px;
  }
`;

interface Props {
  day: string;
  dayData: any;
  setShowInnerLoader: Dispatch<SetStateAction<boolean>>;
}

export const OpenHours_Item = (props: Props) => {
  const _AdminContext = useContext(AdminContext);
  const [hourStart, setHourStart] = useState<any>(props.dayData[0]);
  const [hourEnd, setHourEnd] = useState<any>(props.dayData[1]);

  const handleUpdate = async () => {
    props.setShowInnerLoader(true);
    await updateDoc(
      doc(db, `Barbers/${_AdminContext.adminName}/Data/open-hours`),
      {
        [props.day]: [hourStart, hourEnd],
      }
    ).then(() => {
      props.setShowInnerLoader(false);
      _AdminContext.setSuccessModalState({
        show: true,
        text: "הפעולה בוצעה בהצלחה",
      });
    });
  };

  return (
    <OpenHoursContainer>
      <a style={{ paddingBottom: "10px" }}>
        {hebrewDays[englishDays.indexOf(props.day)]}
      </a>
      <Form.Select
        aria-label="Default select example"
        required
        onChange={(e) => {
          setHourStart(e.target.value);
        }}
        value={hourStart}
      >
        <option value="" selected disabled>
          בחר שעה
        </option>
        {UseCreateHours().map((hour) => (
          <option value={hour} key={hour}>
            {beautifyHourHook(hour.toString())}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        aria-label="Default select example"
        required
        onChange={(e) => {
          setHourEnd(e.target.value);
        }}
        value={hourEnd}
      >
        <option value="" selected disabled>
          בחר שעה
        </option>
        {UseCreateHours().map((hour) => (
          <option value={hour} key={hour}>
            {beautifyHourHook(hour.toString())}
          </option>
        ))}
      </Form.Select>
      <SaveButton onClick={() => handleUpdate()}>
        <a>שמור</a>
      </SaveButton>
    </OpenHoursContainer>
  );
};
