import React, {
  ReactComponentElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import styled from "styled-components";
import { AdminContext } from "../AdminContainer";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { db } from "../../../../backend/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";

const UsersView = styled.div`
  padding: 20px;
`;

const UsersContainer = styled.div`
  width: 100%;
  height: max-content;
  max-height: 300px;
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  gap: 20px;
`;

const UsersHeader = styled.h3`
  font-family: hebrewLight;
  font-size: 20px;
`;

const PaperStyled = styled(Paper)`
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1 !important;
  border-radius: 10px !important;

  .MuiTablePagination-displayedRows {
    position: absolute;
    right: 0;
    font-family: hebrewBold;
    line-height: 1;
    padding: 0;
    margin: 0 10px;
  }

  .MuiTablePagination-actions {
    position: absolute;
    left: 0;
    padding: 0;
    margin: 0 10px;
  }

  .MuiSvgIcon-root {
    transform: rotate(180deg) !important;
  }
`;

const NoPermissionsText = styled.a`
  position: relative;
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  margin-top: 30px;
  color: #000;
  z-index: 9999;
`;

// Styled END
// Hooks START

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "שם מלא", minWidth: 100, align: "right" },
  { id: "phone", label: "טלפון", minWidth: 100, align: "right" },
  {
    id: "bookingsAmount",
    label: "כמות ביקורים",
    minWidth: 100,
    align: "right",
  },
  {
    id: "bookingsList",
    label: "רשימת ביקורים",
    minWidth: 100,
    align: "right",
  },
  {
    id: "deletes",
    label: "ביטולים",
    minWidth: 100,
    align: "right",
  },
];

interface Data {
  name: string;
  phone: string;
  bookingsAmount: number;
  deletes: number;
  bookingsList: ReactComponentElement<any>;
}

class UserClass {
  constructor(data: Data) {
    Object.assign(this, data);
  }
}

// Hooks END

export const Users = () => {
  const _AdminContext = useContext(AdminContext);

  const [usersCount, setUsersCount] = useState<number>(0);
  const [usersData, setUsersData] = useState<Array<UserClass>>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<any>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const collectionRef = collection(db, "Users");
    getDocs(collectionRef).then((docs) => {
      setUsersCount(docs.size);
      setRowsPerPage(Math.ceil(docs.size / 2));
    });
  }, []);

  useEffect(() => {
    _AdminContext.setIsLoading(true);
    const fetchData = async () => {
      const collectionRef = collection(db, "Users");
      const queryFetch =
        page === 0
          ? query(collectionRef, orderBy("Visited", "desc"), limit(rowsPerPage))
          : query(
              collectionRef,
              orderBy("Visited", "desc"),
              startAfter(lastVisible),
              limit(rowsPerPage)
            );
      const docSnapshot = await getDocs(queryFetch);
      setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
      const usersArray: Array<UserClass> = [];
      docSnapshot.docs.map((doc) => {
        usersArray.push(
          new UserClass({
            name: doc.data().Name,
            phone: doc.data().Phone,
            bookingsAmount: doc.data().Visited,
            deletes: doc.data().Deletes,
            bookingsList: (
              <FileOpenIcon
                onClick={() =>
                  _AdminContext.setListModalState({
                    show: true,
                    list: Object.keys(doc.data().Dates),
                  })
                }
              />
            ),
          })
        );
      });
      setUsersData(usersArray);
      _AdminContext.setIsLoading(false);
    };
    try {
      if (rowsPerPage !== 1 && _AdminContext.adminName === "Drory") fetchData();
      else {
        _AdminContext.setIsLoading(false);
      }
    } catch (error) {
      _AdminContext.setIsLoading(false);
      _AdminContext.setErrorModalState({ show: true });
    }
  }, [page, rowsPerPage]);

  return (
    <UsersView id="scrollParent">
      {_AdminContext.adminName === "Drory" ? (
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          scrollableParentSelector={"#scrollParent"}
        >
          <UsersContainer>
            <UsersHeader>{`כמות משתמשים: ${usersCount}`}</UsersHeader>
            <PaperStyled sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 350 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontFamily: "hebrewBold",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersData
                      ? usersData.map((row, i) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              // @ts-ignore
                              key={i}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ fontFamily: "hebrewLight" }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                labelRowsPerPage={"שורות בכל עמוד:"}
                labelDisplayedRows={(paginationInfo) =>
                  `עמוד: ${paginationInfo.page + 1}`
                }
                dir={"rtl"}
                component="div"
                count={usersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </PaperStyled>
          </UsersContainer>
        </AnimationOnScroll>
      ) : (
        <NoPermissionsText>
          אין לך הרשאות לצפות בעמוד המשתמשים
        </NoPermissionsText>
      )}
    </UsersView>
  );
};
