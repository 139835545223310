import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import fadeIn from "react-animations/lib/fadeIn";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCalendarAlt,
  faIdBadge,
  faEnvelope,
  faSkiingNordic,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { AdminColors } from "../_Utils/colors";
import Div100vh from "react-div-100vh";
import { AdminContext } from "../AdminContainer";

// Animations START

const fadeInAnimation = keyframes`${fadeIn}`;

// Animation END
// Styled-Components START

const AdminNavView = styled(Div100vh)`
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-auto-rows: 1fr auto;
  z-index: 98;
  animation: 0.5s ${fadeInAnimation};
  touch-action: none;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5);
`;

const NavView = styled.div`
  display: grid;
  place-items: center;
  grid-gap: 10px;
  text-align: center;
  height: max-content;

  a {
    font-size: 1.2em;
    font-family: hebrewExtraBold;
    color: ${AdminColors.NAV_BLUE} !important;

    &:hover {
      color: ${AdminColors.NAV_BLUE} !important;
    }
  }

  svg {
    font-size: 1.3em;
  }

  li {
    margin: 25px;
    text-decoration: none !important;

    &::before {
      content: "";
      position: absolute;
      bottom: -15px;
      right: 50%;
      transform: translateX(50%);
      width: 150px;
      height: 1px;
      background-color: ${AdminColors.NAV_DIVIDER};
    }
  }
`;

const NavDuo = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  place-items: center;
  gap: 20px;
`;

// Styled-Components END
// Interfaces & Types START

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

// Interfaces & Types END

export const AdminNav = (props: Props) => {
  const _AdminContext = useContext(AdminContext);
  const disabled: boolean = _AdminContext.adminName !== "Drory";

  const handleClick = (shouldDisable: boolean) => {
    if (shouldDisable)
      return _AdminContext.setErrorModalState({
        show: true,
        text: "אין לך הרשאות",
      });
    return props.setOpen(false);
  };

  return (
    <AdminNavView>
      <NavView>
        <nav>
          <ul>
            <NavDuo>
              <Link to="/admin">
                <AnimationOnScroll animateIn="animate__fadeInRight">
                  <li onClick={() => handleClick(false)}>
                    <FontAwesomeIcon icon={faHome} />
                    <br />
                    ראשי
                  </li>
                </AnimationOnScroll>
              </Link>
              <Link to="/admin/appointments">
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                  <li onClick={() => handleClick(false)}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <br />
                    יומן תורים
                  </li>
                </AnimationOnScroll>
              </Link>
            </NavDuo>
            <NavDuo>
              <Link to="/admin/appointments-types">
                <AnimationOnScroll animateIn="animate__fadeInRight">
                  <li onClick={() => handleClick(false)}>
                    <FontAwesomeIcon icon={faIdBadge} />
                    <br />
                    סוגי תורים
                  </li>
                </AnimationOnScroll>
              </Link>
              <Link to={disabled ? "/admin" : "/admin/system-messages"}>
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                  <li onClick={() => handleClick(disabled)}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <br />
                    הודעת מערכת
                  </li>
                </AnimationOnScroll>
              </Link>
            </NavDuo>
            <NavDuo>
              <Link to="/admin/vacations">
                <AnimationOnScroll animateIn="animate__fadeInRight">
                  <li onClick={() => handleClick(false)}>
                    <FontAwesomeIcon icon={faSkiingNordic} />
                    <br />
                    חופשות והפסקות
                  </li>
                </AnimationOnScroll>
              </Link>
              <Link to={disabled ? "/admin" : "/admin/users"}>
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                  <li onClick={() => handleClick(disabled)}>
                    <FontAwesomeIcon icon={faUsers} />
                    <br />
                    רשימת משתמשים
                  </li>
                </AnimationOnScroll>
              </Link>
            </NavDuo>
          </ul>
        </nav>
      </NavView>
    </AdminNavView>
  );
};
