import React, { useContext, useEffect } from "react";
import {
  BookingContext,
  StagesInterface,
  stagesStrings,
} from "../BookingContainer";
import styled, { keyframes } from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import { HeaderText } from "./BarberPickStage";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";
import { addDays } from "date-fns";
import { getDay } from "date-fns";
import fadeInLeft from "react-animations/lib/fadeInLeft";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import moment from "moment";

// Datepicker localization
registerLocale("he", he);

// Animations START

const fadeInAnimation = keyframes`${fadeInLeft}`;

// Animation END
// Styled Components START

const DateContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 20px;

  .react-datepicker {
    background-color: transparent;
    border: none;
    animation: 0.5s ${fadeInAnimation};
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker__day {
    color: #dddddd;
    text-align: center;
    padding-top: 10px;
  }

  .react-datepicker__current-month {
    font-size: 18px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 3em;
    height: 3em;
    font-family: hebrewExtraBold;
    font-size: 16px;
    border-radius: 0;
    margin: 0;
  }

  .react-datepicker__month .react-datepicker__day {
    background-color: #1685d8;
  }

  .react-datepicker__current-month {
    margin-top: 2px;
    font-family: hebrewExtraBold;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__navigation {
    height: 1.5em;
    width: 1.5em;
    margin: 1.5em;
    font-size: 16px;
  }

  .disabled-date {
    background-color: #fff;
  }

  .react-datepicker__day--selected {
    background-color: #1685d8 !important;
    color: #fff;
  }

  .react-datepicker__day--disabled {
    background-color: #141414 !important;
  }

  .react-datepicker__navigation--previous--disabled {
    opacity: 0.5;
  }
`;

const DatePickerStyled = styled(DatePicker)``;

// Styled Components END
// Interfaces & Types START

interface ExcludedDatesInterface {
  start: Date;
  end: Date;
}

// Interfaces & Types END
// Hooks START

const excludedDates = (data: object): ExcludedDatesInterface[] => {
  return Object.values(data).map((singleVacation: Array<string>) => {
    const startSplit = singleVacation[0].replace("-", "").replace("-", "");
    const endSplit = singleVacation[1].replace("-", "").replace("-", "");
    return {
      start: new Date(
        parseInt(startSplit.slice(4, 8)),
        parseInt(startSplit.slice(2, 4)) - 1,
        parseInt(startSplit.slice(0, 2))
      ),
      end: new Date(
        parseInt(endSplit.slice(4, 8)),
        parseInt(endSplit.slice(2, 4)) - 1,
        parseInt(endSplit.slice(0, 2))
      ),
    };
  });
};

// Hooks END

const DatePickStage = () => {
  // useContext
  const _BookingContext = useContext(BookingContext);

  // Firebase Related
  const vacationsRef = doc(
    useFirestore(),
    `Barbers/${_BookingContext.userPicks.barber}/Data/Break-Vacation`
  );
  const { status, data } = useFirestoreDocData(vacationsRef, {
    suspense: false,
  });

  // Utils
  const handleBarberPick = (stage: StagesInterface, date: Date) => {
    const dateMoment = moment(date).format("DD-MM-YYYY");
    _BookingContext?.setDatePick({
      date: dateMoment,
      dayOfWeek: date.getDay(),
      dateLong: date,
    });
    _BookingContext?.setStage(stage);
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    //NOTE: New Barber area
    if (_BookingContext.userPicks.barber === 'Drory') {
      return day !== 2 && day !== 6;
    }
    return day !== 6;
   };

  // useEffect
  useEffect(() => {
    _BookingContext?.setProgress(50);
  }, []);

  useEffect(() => {
    status === "loading"
      ? _BookingContext?.setLoading(true)
      : _BookingContext?.setLoading(false);
  }, [status]);

  return (
    <>
      {_BookingContext?.isLoading ? null : (
        <DateContainer>
          <HeaderText>בחר תאריך</HeaderText>
          <DatePickerStyled
            inline
            selected={new Date()}
            onChange={(date: Date) =>
              handleBarberPick(
                {
                  num: stagesStrings[3].num,
                  str: stagesStrings[3].str,
                },
                date
              )
            }
            locale={he}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(4, 5)}
            showDisabledMonthNavigation
            filterDate={isWeekday}
            minDate={new Date()}
            maxDate={addDays(new Date(), 9)}
            excludeDateIntervals={data ? excludedDates(data) : null}
          />
        </DateContainer>
      )}
    </>
  );
};

export default DatePickStage;
