import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import swiper12 from "../../media/gallery/gallery12.webp";
import swiper13 from "../../media/gallery/gallery13.webp";
import swiper14 from "../../media/gallery/gallery14.webp";
import swiper15 from "../../media/gallery/gallery15.webp";
import swiper1 from "../../media/gallery/gallery1.webp";
import swiper2 from "../../media/gallery/gallery2.webp";
import swiper3 from "../../media/gallery/gallery3.webp";
import swiper4 from "../../media/gallery/gallery4.webp";
import swiper5 from "../../media/gallery/gallery5.webp";
import swiper6 from "../../media/gallery/gallery6.webp";
import swiper7 from "../../media/gallery/gallery7.webp";
import swiper9 from "../../media/gallery/gallery9.webp";
import swiper10 from "../../media/gallery/gallery10.webp";

const HaircutSwiperView = styled.div`
  position: relative;
`;

const SwiperStyled = styled(Swiper)`
  position: relative;
  width: 300px;
  height: 300px;
  box-shadow: 0px 5px 0px 5px #3c97ffab, 0 10px 0px 10px #2b6cb6ab,
    0px 20px 10px 20px #1d4777ab, 5px 5px 5px 5px rgb(0, 0, 0);
  border-radius: 25px;
  border: 5px solid #bbdbff;
  margin-bottom: 100px;

  .swiper-slide {
    box-shadow: inset 0 0 0 10px #bbdbff;
    box-sizing: border-box;

    & img {
      display: block;
      width: 300px;
      height: 300px;
    }

    #swiper14Image {
      position: absolute;
      width: auto;
      height: 300px;
      right: 50%;
      transform: translateX(50%) scale(1.3);
    }
  }
`;

export const HaircutSwiper = () => {
  return (
    <HaircutSwiperView>
      <SwiperStyled
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={swiper12} alt={"swiper 1"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper13} alt={"swiper 2"} />
        </SwiperSlide>
        <SwiperSlide>
          <img id="swiper14Image" src={swiper14} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper1} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper2} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper3} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper4} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper6} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper9} alt={"swiper 3"} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper10} alt={"swiper 3"} />
        </SwiperSlide>
      </SwiperStyled>
    </HaircutSwiperView>
  );
};
