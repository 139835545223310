import React, { Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { Backdrop, Fade, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { AdminColors } from "./colors";
import { ModalInterface } from "../AdminContainer";

const ListModalStyled = styled.div``;

const ModalStyled = styled(Modal)`
  .MuiBox-root {
    padding: 0;
    outline: 0 !important;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: max-content;
  padding: 20px 0;
  background-color: #c0c0c0;
  text-align: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-bottom: 15px;
`;

const FooterContainer = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  grid-auto-rows: 20px 10px auto;
  padding-bottom: 15px;
`;

const CloseTextButton = styled.a`
  color: #fff;
  background-color: ${AdminColors.NAV_BLUE};
  padding: 2.5px 20px;
  border-radius: 25px;
  font-size: 20px;
  font-family: hebrewLight;
`;

const ListBody = styled.div`
  width: 100%;
  padding: 10px 50px;
  padding-bottom: 15px;
  max-height: 200px;
  overflow-y: auto;
  display: grid;
  place-items: center;
  margin-bottom: 30px;
`;

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

interface Props {
  dates?: string[];
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<ModalInterface>>;
}

export const ListModal = (props: Props) => {
  return (
    <ListModalStyled>
      <ModalStyled
        open={props.modalState}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalState}>
          <Box sx={style}>
            <HeaderContainer></HeaderContainer>
            <FooterContainer>
              <h4>רשימת ביקורים:</h4>
              <br />
              <ListBody>
                {props.dates?.map((date) => {
                  return <a key={date}>{date}</a>;
                })}
              </ListBody>
              <CloseTextButton
                onClick={() => props.setModalState({ show: false })}
              >
                סגור
              </CloseTextButton>
            </FooterContainer>
          </Box>
        </Fade>
      </ModalStyled>
    </ListModalStyled>
  );
};
