import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import Modaly from "react-bootstrap/Modal";
import { Button, CloseButton } from "react-bootstrap";
import {
  BookingContext,
  stagesStrings,
} from "../Pages/BookingPage/BookingContainer";

// Styled-Components START

const ModalContainer = styled.div``;

const ModalStyled = styled(Modaly)`
  background: transparent;
  display: grid !important;
  grid-template-rows: auto;
  place-items: center;

  .modal-content {
    max-width: 350px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    box-shadow: 0 0 5px 0 #ffffff;
  }

  @media (min-width: 576px) {
    .modal-sm {
      max-width: 350px;
    }
  }

  .modal-title {
    color: #fff !important;
  }

  .modal-body {
    color: #fff !important;
  }

  .modal-header {
    .btn-close {
      opacity: 1;
    }
  }
`;

// Styled-Components END

// Interfaces & Types START

interface Props {
  modalTitle: string;
  setDisplayModal?: Dispatch<SetStateAction<boolean>>;
  isFast: boolean;
}

// Interfaces & Types END

const ModalConfirmation = (props: Props) => {
  const [show, setShow] = useState(true);

  const _BookingContext = useContext(BookingContext);

  const handlePick = (extra: boolean) => {
    if (!_BookingContext.isFast) {
      _BookingContext?.setStage({
        num: stagesStrings[2].num,
        str: stagesStrings[2].str,
      });
    } else {
      _BookingContext?.setStage({
        num: stagesStrings[3].num,
        str: stagesStrings[3].str,
      });
    }
    if (props.setDisplayModal) props.setDisplayModal(false);
    if (extra) _BookingContext?.setExtraPick(true);
    else _BookingContext?.setExtraPick(false);
  };

  return (
    <ModalContainer>
      <ModalStyled
        show={show}
        onHide={() => setShow(false)}
        backdrop={"static"}
        keyboard={false}
        size={"sm"}
      >
        <Modaly.Header>
          <Modaly.Title>{props.modalTitle}</Modaly.Title>
          <CloseButton variant="white" onClick={() => handlePick(false)} />
        </Modaly.Header>
        <Modaly.Body>
          <a>גבר! תרצה להוסיף שעוות לחיים/אזניים/בין הגבות/אף?</a>
          <br />
          <a>איזור 1 - ₪15 </a> <br />
          <a>2 איזורים - ₪25 </a> <br />
          <a>3 איזורים ומעלה - ₪30 </a> <br />
        </Modaly.Body>
        <Modaly.Footer>
          <Button variant="secondary" onClick={() => handlePick(false)}>
            לא הפעם
          </Button>
          <Button variant="primary" onClick={() => handlePick(true)}>
            ברור שכן
          </Button>
        </Modaly.Footer>
      </ModalStyled>
    </ModalContainer>
  );
};

export default ModalConfirmation;
