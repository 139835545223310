import React, { useContext } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { AdminColors } from "./colors";
import { Divider, Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdminContext } from "../AdminContainer";
// Styled START

const CreateButtonView = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: max-content;
  height: max-content;
  padding: 10px;
  z-index: 2;

  svg {
    width: 55px;
    height: 55px;
    color: #fff;
    background-color: ${AdminColors.NAV_BLUE};
    border: 4px solid rgba(159, 210, 255, 0.76);
    border-radius: 50%;
    box-shadow: 0 0 10px 0 #ffffff;
    padding: 5px;
  }
`;

const MenuStyled = styled(Menu)`
  z-index: 2 !important;
  .MuiPaper-root {
    box-shadow: 0 0 5px 0 #2887e6, 0 5px 0 0 #3f90e4, 0 10px 5px 0 #559ae1;
    background-color: ${AdminColors.NAV_BLUE};
    border-radius: 10px;
  }

  .MuiMenuItem-root {
    color: #fff;
    font-family: hebrewLight;
    font-size: 20px;

    &:focus {
      background-color: transparent;
    }

    svg {
      padding-left: 5px;
    }

    #haircutIcon,
    #skiingIcon {
      width: 30px;
    }
  }
`;

const DividerStyled = styled(Divider)`
  color: #fff;
`;

// Styled END

interface ModalTypeInterface {
  modalType:
    | "appointments"
    | "breaks-day"
    | "breaks-date"
    | "vacations"
    | "users";
}

export const CreationButton = () => {
  const _AdminContext = useContext(AdminContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (type?: ModalTypeInterface) => {
    setAnchorEl(null);
    if (type?.modalType === "appointments")
      _AdminContext.setAppointmentCreationState(true);
    else if (type?.modalType === "users") {
      _AdminContext.setUserCreationState(true);
    } else if (
      type?.modalType === "breaks-day" ||
      type?.modalType === "breaks-date" ||
      type?.modalType === "vacations"
    ) {
      _AdminContext.setBreaksCreationState({
        show: true,
        type: type.modalType,
      });
    }
  };

  return (
    <CreateButtonView>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        style={{ zIndex: 98 }}
      >
        <AddIcon />
      </Button>
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClick()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClick({ modalType: "appointments" })}>
          <ContentCutIcon fontSize={"medium"} id="haircutIcon" />
          תור חדש
        </MenuItem>
        <DividerStyled />
        <MenuItem onClick={() => handleClick({ modalType: "users" })}>
          <PersonAddAltIcon fontSize={"large"} id={"personIcon"} />
          צור משתמש
        </MenuItem>
        <DividerStyled />
        <MenuItem onClick={() => handleClick({ modalType: "breaks-day" })}>
          <AllInclusiveIcon fontSize={"large"} />
          הפסקה קבועה
        </MenuItem>
        <DividerStyled />
        <MenuItem onClick={() => handleClick({ modalType: "breaks-date" })}>
          <AccessTimeIcon fontSize={"large"} />
          הפסקה ח״פ
        </MenuItem>
        <DividerStyled />
        <MenuItem onClick={() => handleClick({ modalType: "vacations" })}>
          <DownhillSkiingIcon fontSize={"large"} id={"skiingIcon"} />
          חופשה
        </MenuItem>
      </MenuStyled>
    </CreateButtonView>
  );
};
