import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import {
  BookingContext,
  BookingContextInterface,
  stagesStrings,
} from "../BookingContainer";
import fadeIn from "react-animations/lib/fadeIn";

// Animations START

const fadeInAnimation = keyframes`${fadeIn}`;

// Animations END
// Styled Components START

const BottomNavStyled = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2.5%;
  text-align: left;
`;

const ProgressBarDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  bottom: 10%;
  animation: 1s ${fadeInAnimation};
`;

const BackDivStyled = styled.div<{ disabled: boolean }>`
  position: absolute;
  left: 40px;
  bottom: 40px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: max-content;
`;

const BackText = styled.a`
  font-size: 16px;
  color: #fff;
  font-family: hebrewLight;
  padding-right: 5px;

  &:hover {
    color: #fff;
  }
`;

// Styled Components END

const BottomNavigation = () => {
  const _BookingContext: BookingContextInterface | undefined =
    useContext(BookingContext);

  const [disabled, setDisabled] = useState<boolean>(true);

  const handleBackButton = () => {
    if (
      _BookingContext.stage.num === stagesStrings[3].num &&
      _BookingContext.isFast
    ) {
      _BookingContext?.setStage(stagesStrings[1]);
    } else {
      _BookingContext?.setStage({
        num: _BookingContext?.stage.num - 1,
        str: stagesStrings[_BookingContext?.stage.num - 1].str,
      });
    }
  };

  useEffect(() => {
    _BookingContext?.stage.num === stagesStrings[0].num
      ? setDisabled(true)
      : setDisabled(false);
  }, [_BookingContext?.stage]);

  return (
    <BottomNavStyled>
      <ProgressBarDiv>
        <ProgressBar
          now={_BookingContext?.progress}
          animated={true}
          visuallyHidden={true}
          variant={"progressBarCustom"}
        />
      </ProgressBarDiv>
      <BackDivStyled
        disabled={disabled}
        onClick={!disabled ? handleBackButton : () => null}
      >
        <FontAwesomeIcon icon={faUndo} />
        <BackText>חזור</BackText>
      </BackDivStyled>
    </BottomNavStyled>
  );
};

export default BottomNavigation;
