import React, { useContext, useState } from "react";
import styled from "styled-components";
import logoImage from "../../media/logoWhite.webp";
import { Squash as Hamburger } from "hamburger-react";
import Nav from "./Nav";
import { AppMainContext, MainContextInterface } from "../../App";

// Fontawesome

const NavContainerStyled = styled.div``;

const HeaderStyled = styled.div<{ currentPage?: string }>`
  position: ${(props) =>
    props.currentPage === ("Home" || "Loader" || "Delete")
      ? "fixed"
      : "relative"};
  height: 75px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  z-index: 99;
  background-color: ${(props) =>
    props.currentPage === "Admin" ? "#2894ff" : "transparent"};
  color: #fff;

  img {
    margin-top: 10px;
    margin-left: 25px;
    height: 60px;
  }

  .hamburger-react {
    position: absolute;
    top: 60%;
    right: 25px;
    transform: translateY(-50%);
  }
`;

const HeaderOverlay = styled.div`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 200px;
  z-index: 5;
  touch-action: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`;

const NavContainer = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const _AppMainContext: MainContextInterface | undefined =
    useContext(AppMainContext);

  return (
    <>
      <NavContainerStyled>
        <HeaderStyled currentPage={_AppMainContext?.currentPage}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
          <img src={logoImage} alt={"Logo Image"} />
        </HeaderStyled>
        {isOpen ? <Nav setOpen={setOpen} /> : null}
      </NavContainerStyled>
      <HeaderOverlay />
    </>
  );
};

export default NavContainer;
