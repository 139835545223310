import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import bookingHeader from "../../../media/bookingHeader.webp";
import { AppMainContext, MainContextInterface } from "../../../App";
import Loader from "../../Utils/Loader";
import BarberPickStage from "./Stages/BarberPickStage";
import BottomNavigation from "./Stages/BottomNavigation";
import HairstylePickStage from "./Stages/HairstylePickStage";
import DatePickStage from "./Stages/DatePickStage";
import { TimePickStage } from "./Stages/TimePickStage";
import ConfirmationStage from "./Stages/ConfirmationStage";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import { InformativeModal } from "../../Utils/InformativeModal";
import { ModalInterface } from "../AdminPage/AdminContainer";
import { ErrorModal } from "../../Utils/ErrorModal";
import { SuccessModal } from "../../Utils/SuccessModal";
import { db } from "../../../backend/firebase";
import { MaintananceMode } from "./Stages/_Utils/MaintananceMode";

// Styled-Components START

const BookingContainerStyled = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  place-items: center;
  gap: 20px;
  overflow: hidden;
  height: 100vh;
  //touch-action: none;
  color: #fff;
`;

const BookingHeaderStyled = styled.div`
  margin-top: 20px;

  img {
    width: 150px;
  }
`;

// Styled-Components END
// Interfaces & Types START

export interface BookingContextInterface {
  progress: number;
  stage: StagesInterface;
  userPicks: UserPicksInterface;
  isLoading: boolean;
  datesTried: string[];
  isFast: boolean;
  setProgress: Dispatch<SetStateAction<number>>;
  setStage: Dispatch<SetStateAction<StagesInterface>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setBarberPick: Dispatch<SetStateAction<string | undefined>>;
  setHairstylePick: Dispatch<SetStateAction<HairstyleInterface>>;
  setExtraPick: Dispatch<SetStateAction<boolean | undefined>>;
  setDatePick: Dispatch<SetStateAction<DatePickInterface | undefined>>;
  setTimePick: Dispatch<SetStateAction<string | undefined>>;
  setInformativeModalState: Dispatch<SetStateAction<ModalInterface>>;
  setErrorModalState: Dispatch<SetStateAction<ModalInterface>>;
  setSuccessModalState: Dispatch<SetStateAction<ModalInterface>>;
  setDatesTried: Dispatch<SetStateAction<string[]>>;
}

export interface StagesInterface {
  num: number;
  str: string;
}

export interface UserPicksInterface {
  barber: string;
  hairstyle: HairstyleInterface;
  extra: boolean;
  date: DatePickInterface;
  time: string;
}

interface DatePickInterface {
  date: string;
  dayOfWeek: number;
  dateLong: Date;
}

interface HairstyleInterface {
  hairstyleDuration: number;
  hairstyleLabel: string;
}

// Interfaces & Types END

export const BookingContext = createContext<BookingContextInterface>({
  progress: 0,
  stage: { num: 0, str: "BarberPickStage" },
  userPicks: {
    barber: "",
    hairstyle: { hairstyleDuration: 0, hairstyleLabel: "" },
    extra: false,
    date: { date: "", dayOfWeek: 0, dateLong: new Date() },
    time: "",
  },
  isLoading: true,
  datesTried: [],
  isFast: true,
  setProgress: () => undefined,
  setStage: () => undefined,
  setLoading: () => undefined,
  setBarberPick: () => undefined,
  setHairstylePick: () => undefined,
  setExtraPick: () => undefined,
  setDatePick: () => undefined,
  setTimePick: () => undefined,
  setInformativeModalState: () => undefined,
  setErrorModalState: () => undefined,
  setSuccessModalState: () => undefined,
  setDatesTried: () => undefined,
});

export const stagesStrings: StagesInterface[] = [
  { num: 0, str: "BarberPickStage" },
  { num: 1, str: "HairstylePickStage" },
  { num: 2, str: "DatePickStage" },
  { num: 3, str: "TimePickStage" },
  { num: 4, str: "ConfirmationStage" },
];

const BookingContainer = () => {
  // Firebase Related
  const firestoreInstance = getFirestore(useFirebaseApp());
  // const isFast = window.location.hash === "#fast";
  const isFast = false;

  // useContext
  const _AppMainContext: MainContextInterface | undefined =
    useContext(AppMainContext);

  // useState
  const [isLoading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [stage, setStage] = useState<StagesInterface>({
    num: 0,
    str: "BarberPickStage",
  });
  const [barberPick, setBarberPick] = useState<string>("");
  const [hairstylePick, setHairstylePick] = useState<HairstyleInterface>({
    hairstyleDuration: 0,
    hairstyleLabel: "",
  });
  const [datePick, setDatePick] = useState<DatePickInterface>({
    date: "",
    dayOfWeek: 0,
    dateLong: new Date(),
  });
  const [timePick, setTimePick] = useState<string>("");
  const [extraPick, setExtraPick] = useState<boolean>(false);

  const [errorModalState, setErrorModalState] = useState<ModalInterface>({
    show: false,
  });
  const [successModalState, setSuccessModalState] = useState<ModalInterface>({
    show: false,
  });
  const [datesTried, setDatesTried] = useState<string[]>([]);
  const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false);

  const [informativeModalState, setInformativeModalState] =
    useState<ModalInterface>({ show: false });

  const userPicks: UserPicksInterface = {
    barber: barberPick,
    hairstyle: hairstylePick,
    extra: extraPick,
    date: datePick,
    time: timePick,
  };
  // useEffect
  useEffect(() => {
    _AppMainContext?.setCurrentPage("Book");

    const fetchData = async () => {
      setLoading(true);
      await getDoc(doc(db, "Barbers/Drory/Data/Message")).then((result) => {
        if (result.data()?.Checked) {
          setInformativeModalState({
            show: true,
            text: result.data()?.Message,
          });
        }
      });
      await getDoc(doc(db, "Barbers/Drory/Data/maintenance")).then((result) => {
        setMaintenanceMode(result.data()?.status);
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <BookingContext.Provider
      value={{
        progress,
        stage,
        isLoading,
        userPicks,
        datesTried,
        isFast,
        setProgress,
        setStage,
        setLoading, // @ts-ignore
        setBarberPick, // @ts-ignore
        setHairstylePick, // @ts-ignore
        setExtraPick, // @ts-ignore
        setDatePick, // @ts-ignore
        setTimePick,
        setInformativeModalState,
        setErrorModalState: setErrorModalState,
        setSuccessModalState: setSuccessModalState,
        setDatesTried,
      }}
    >
      {isLoading ? <Loader display={true} /> : null}
      <InformativeModal
        modalState={informativeModalState}
        setModalState={setInformativeModalState}
      />
      {maintenanceMode ? <MaintananceMode /> : null}
      <BookingContainerStyled>
        <>
          <BookingHeaderStyled>
            <img src={bookingHeader} alt={"Booking Header"} />
          </BookingHeaderStyled>
          <FirestoreProvider sdk={firestoreInstance}>
            {stage.str === stagesStrings[0].str ? <BarberPickStage /> : null}
            {stage.str === stagesStrings[1].str ? <HairstylePickStage /> : null}
            {stage.str === stagesStrings[2].str ? <DatePickStage /> : null}
            {stage.str === stagesStrings[3].str ? <TimePickStage /> : null}
            {stage.str === stagesStrings[4].str ? <ConfirmationStage /> : null}
          </FirestoreProvider>
          <BottomNavigation />
        </>
        <ErrorModal
          modalState={errorModalState.show}
          errorText={errorModalState?.text}
          setModalState={setErrorModalState}
        />
        <SuccessModal
          modalState={successModalState.show}
          successText={successModalState.text}
          setModalState={setSuccessModalState}
        />
      </BookingContainerStyled>
    </BookingContext.Provider>
  );
};

export default BookingContainer;
