import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { BookingContext } from "../BookingContainer";
import { HeaderText } from "./BarberPickStage";
import HourButton from "./_Utils/HourButton";
import { doc, getDoc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { db, functions } from "../../../../backend/firebase";
import moment from "moment";
import { LoaderInner } from "../../AdminPage/_Utils/LoaderInner";
import { httpsCallable } from "firebase/functions";

const TimePickContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 20px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  display: grid;
  place-items: center;
  gap: 10px;
`;

const NoBookingText = styled.h3`
  width: 100%;
  text-align: right;
  color: #fff;
  font-size: 24px;
  font-family: hebrewLight;
  padding: 0 20px;
`;

// Styled END
// Hooks START

export const englishDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const sortAppointments = (appointmentsArray: any) => {
  return appointmentsArray.sort((a, b) => {
    if (parseInt(Object.keys(a)[0]) < parseInt(Object.keys(b)[0])) return -1;
    return 1;
  });
};

// Hooks END

export interface DailyHoursInterface {
  hour: number;
  available: boolean;
}

export const TimePickStage = () => {
  const _BookingContext = useContext(BookingContext);

  const [hoursState, setHoursState] = useState<any>([]);
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(true);

  const isFast = _BookingContext.isFast;

  const FetchHours = () => {
    const appointmentsResult = useFirestoreDocData(
      doc(
        useFirestore(),
        `Barbers/${_BookingContext?.userPicks.barber}/Dates/${_BookingContext?.userPicks.date?.date}`
      )
    );
    const daysResult = useFirestoreDocData(
      doc(
        useFirestore(),
        `Barbers/${_BookingContext?.userPicks.barber}/Data/Breaks-Days`
      )
    );
    const datesResult = useFirestoreDocData(
      doc(
        useFirestore(),
        `Barbers/${_BookingContext?.userPicks.barber}/Data/Breaks-Dates`
      )
    );
    const openHoursResult = useFirestoreDocData(
      doc(
        useFirestore(),
        `Barbers/${_BookingContext?.userPicks.barber}/Data/open-hours`
      )
    );

    const hoursArray: Array<any> = [];
    const generatedHours: DailyHoursInterface[] = [];
    let finishedFetching: boolean = false;
    if (
      appointmentsResult.status === "success" &&
      datesResult.status === "success" &&
      daysResult.status === "success" &&
      openHoursResult.status === "success"
    ) {
      // Breaks Days DECLARATION
      const breaksDay: Array<any> =
        daysResult.data[ // @ts-ignore
          englishDays[_BookingContext?.userPicks.date?.dayOfWeek]
        ];
      // Breaks Dates DECLARATION
      const breaksDate: Array<any> = // @ts-ignore
        datesResult.data[_BookingContext?.userPicks.date.date];
      //
      const openHoursArray: Array<any> =
        openHoursResult.data[ // @ts-ignore
          englishDays[_BookingContext?.userPicks.date?.dayOfWeek]
        ];
      let cnt: number = 1;
      const minNum = parseInt(openHoursArray[0]);
      const maxNum = parseInt(openHoursArray[1]);
      for (let i = 600; i < 2300; i += 5) {
        if (600 + cnt * 100 - i === 40) {
          i = 600 + cnt * 100;
          cnt++;
        }
        if (i < minNum) generatedHours.push({ hour: i, available: false });
        else if (i > maxNum) generatedHours.push({ hour: i, available: false });
        else generatedHours.push({ hour: i, available: true });
      }
      if (breaksDay !== undefined) {
        breaksDay.map((breakItem) => {
          const breakTemp = breakItem.split("/");
          hoursArray.push({
            [breakTemp[0]]: parseInt(breakTemp[1]),
          });
        });
      }
      if (breaksDate !== undefined) {
        breaksDate.map((breakItem) => {
          const breakTemp = breakItem.split("/");
          hoursArray.push({
            [breakTemp[0]]: parseInt(breakTemp[1]),
          });
        });
      }
      if (appointmentsResult.data !== undefined) {
        Object.entries(appointmentsResult.data).map((book) => {
          if (book[0] !== "NO_ID_FIELD") {
            hoursArray.push({
              [book[0]]: book[1].typeDuration,
            });
          }
        });
      }
      sortAppointments(hoursArray);
      const bookingDuration: number = _BookingContext.userPicks.extra
        ? _BookingContext?.userPicks.hairstyle.hairstyleDuration + 1
        : _BookingContext?.userPicks.hairstyle.hairstyleDuration;
      for (let i = 0; i < hoursArray.length; i++) {
        for (let j = 0; j < generatedHours.length; j++) {
          const hour = parseInt(Object.entries(hoursArray[i])[0][0]);
          const hourDuration = Object.entries(hoursArray[i])[0][1];
          if (hour === generatedHours[j].hour) {
            // @ts-ignore
            for (let k = 0; k < hourDuration; k++) {
              if (j + k < generatedHours.length) {
                generatedHours[j + k].available = false;
              }
            }
            for (let p = 0; p < bookingDuration; p++) {
              if (j - p >= 0) {
                generatedHours[j - p].available = false;
              }
            }
            break;
          }
        }
      }
      const generatedHoursLength = generatedHours.length;
      let maxNumIndex: number;
      generatedHours.find((hour, index) => {
        if (hour.hour === maxNum) {
          maxNumIndex = index;
        }
      });
      for (let j = 0; j < bookingDuration; j++) {
        // @ts-ignore
        generatedHours[maxNumIndex - j].available = false;
      }
      let counter = 0;
      for (let i = 0; i < generatedHoursLength - 1; i++) {
        if (generatedHours[i].available) counter++;
        if (
          generatedHours[i + 1].available === false ||
          counter === bookingDuration
        ) {
          for (let j = counter - 2; j >= 0; j--) {
            generatedHours[i - j].available = false;
          }
          counter = 0;
        }
      }
      finishedFetching = true;
    }
    if (showInnerLoader) setTimeout(() => setShowInnerLoader(false), 500);
    _BookingContext?.setLoading(false);
    const hoursToDisplay = generatedHours.some((obj) => obj.available);
    if (finishedFetching && !hoursToDisplay) {
      if (
        !_BookingContext.datesTried.includes(
          _BookingContext.userPicks.date.date
        )
      ) {
        _BookingContext.setDatesTried((state) => [
          ...state,
          _BookingContext.userPicks.date.date,
        ]);
        httpsCallable(
          functions,
          "noBookingsIncrementer"
        )({ userPickBarber: _BookingContext.userPicks.barber });
      }
      return (
        <AnimationOnScroll animateIn="animate__fadeIn">
          <NoBookingText>
            <a>לא נשארו תורים פנויים ליום זה.</a>
            <br />
            <a>התספורת ממש דחופה לך?</a>
            <br />
            <a>
              אפשר לקבוע תור V.I.P שבו אפתח במיוחד בשבילך את המספרה מחוץ לשעות
              העבודה!
            </a>
            <br />
            <a>לתיאום - </a>
            <a href="tel:+972524597306">0524597306</a>
          </NoBookingText>
        </AnimationOnScroll>
      );
    }

    return (
      <ButtonsContainer>
        {generatedHours.map((hour, i) => {
          if (hour.available) {
            return <HourButton hour={hour.hour} key={i} type={"normal"} />;
          }
          return;
        })}
      </ButtonsContainer>
    );
  };

  const fetchFastHours = async () => {
    const today: Date = new Date();
    let breakCounter: number = 0;
    const hoursArray: any[] = [];
    do {
      const otherDay: Date = new Date(today);
      otherDay.setHours(0, 0, 0, 0);
      const dateBefore = otherDay.setDate(otherDay.getDate() + breakCounter);
      const dayOfWeek: number = moment(dateBefore).weekday();
      if (
        (dayOfWeek !== 2 && dayOfWeek !== 6)) {
        const date = moment(dateBefore).format("DD-MM-YYYY");
        const dayOfWeek = moment(dateBefore).weekday();
        const dateLong = new Date(dateBefore);
        const booksArray: Array<any> = [];
        const generatedHours: DailyHoursInterface[] = [];

        const datesPromise = getDoc(
          doc(db, `Barbers/${_BookingContext?.userPicks.barber}/Dates/${date}`)
        ).then((result) => {
          if (result.data() !== undefined) {
            Object.entries(result.data()!).map((book) => {
              booksArray.push({
                [book[0]]: book[1].typeDuration,
              });
            });
          }
        });

        const breakDaysPromise = await getDoc(
          doc(
            db,
            `Barbers/${_BookingContext?.userPicks.barber}/Data/Breaks-Days`
          )
        ).then((result) => {
          // @ts-ignore
          return result.data()[englishDays[dayOfWeek]];
        });

        const breakDatesPromise = await getDoc(
          doc(
            db,
            `Barbers/${_BookingContext?.userPicks.barber}/Data/Breaks-Dates`
          )
        ).then((result) => {
          // @ts-ignore
          return result.data()[date];
        });

        const vacationsPromise = await getDoc(
          doc(
            db,
            `Barbers/${_BookingContext?.userPicks.barber}/Data/Break-Vacation`
          )
        ).then((result) => {
          // @ts-ignore
          return result.data();
        });

        const openHoursPromise = await getDoc(
          doc(
            db,
            `Barbers/${_BookingContext?.userPicks.barber}/Data/open-hours`
          )
        ).then((result) => {
          // @ts-ignore
          return result.data()[englishDays[dayOfWeek]];
        });

        const [
          appointmentsResult,
          breaksDaysResult,
          breaksDatesResult,
          vacationResult,
          openHoursResult,
        ] = await Promise.all([
          datesPromise,
          breakDaysPromise,
          breakDatesPromise,
          vacationsPromise,
          openHoursPromise,
        ]);

        let allowContinue: boolean = true;

        await Promise.all(
          // @ts-ignore
          Object.values(vacationResult).map((singleVacation: Array<string>) => {
            const dateMin = singleVacation[0].replace("-", "").replace("-", "");
            const dateMax = singleVacation[1].replace("-", "").replace("-", "");
            if (
              dateLong >=
                new Date(
                  `${parseInt(dateMin.slice(4, 8))}/${parseInt(
                    dateMin.slice(2, 4)
                  )}/${parseInt(dateMin.slice(0, 2))} 00:00`
                ) &&
              dateLong <=
                new Date(
                  `${parseInt(dateMax.slice(4, 8))}/${parseInt(
                    dateMax.slice(2, 4)
                  )}/${parseInt(dateMax.slice(0, 2))} 00:00`
                )
            ) {
              allowContinue = false;
            } else {
              allowContinue = true;
            }
          })
        );
        if (allowContinue) {
          let cnt: number = 1;
          const minNum = parseInt(openHoursResult[0]);
          const maxNum = parseInt(openHoursResult[1]);
          for (let i = 600; i < 2300; i += 5) {
            if (600 + cnt * 100 - i === 40) {
              i = 600 + cnt * 100;
              cnt++;
            }
            if (i < minNum) generatedHours.push({ hour: i, available: false });
            else if (i > maxNum)
              generatedHours.push({ hour: i, available: false });
            else generatedHours.push({ hour: i, available: true });
          }
          if (breaksDaysResult !== undefined) {
            breaksDaysResult.map((breakItem) => {
              const breakTemp = breakItem.split("/");
              booksArray.push({
                [breakTemp[0]]: parseInt(breakTemp[1]),
              });
            });
          }

          if (breaksDatesResult !== undefined) {
            breaksDatesResult.map((breakItem) => {
              const breakTemp = breakItem.split("/");
              booksArray.push({
                [breakTemp[0]]: parseInt(breakTemp[1]),
              });
            });
          }

          const bookingDuration: number = _BookingContext.userPicks.extra
            ? _BookingContext?.userPicks.hairstyle.hairstyleDuration + 1
            : _BookingContext?.userPicks.hairstyle.hairstyleDuration;
          for (let i = 0; i < booksArray.length; i++) {
            for (let j = 0; j < generatedHours.length; j++) {
              const hour = parseInt(Object.entries(booksArray[i])[0][0]);
              const hourDuration = Object.entries(booksArray[i])[0][1];
              if (hour === generatedHours[j].hour) {
                // @ts-ignore
                for (let k = 0; k < hourDuration; k++) {
                  if (j + k < generatedHours.length) {
                    generatedHours[j + k].available = false;
                  }
                }
                for (let p = 0; p < bookingDuration; p++) {
                  if (j - p >= 0) {
                    generatedHours[j - p].available = false;
                  }
                }
                break;
              }
            }
          }
          const generatedHoursLength = generatedHours.length;
          let maxNumIndex: number;
          let counter: number = 0;
          generatedHours.find((hour, index) => {
            if (hour.hour === maxNum) {
              maxNumIndex = index;
            }
          });
          for (let j = 0; j < bookingDuration; j++) {
            // @ts-ignore
            generatedHours[maxNumIndex - j].available = false;
          }
          for (let i = 0; i < generatedHoursLength; i++) {
            if (generatedHours[i] !== undefined && generatedHours[i].available)
              counter++;
            if (
              (generatedHours[i + 1] !== undefined &&
                !generatedHours[i + 1].available) ||
              counter === bookingDuration
            ) {
              for (let j = counter - 2; j >= 0; j--) {
                if (generatedHours[i - j] !== undefined)
                  generatedHours[i - j].available = false;
              }
              counter = 0;
            }
          }
          generatedHours.map((obj) => {
            if (obj !== undefined && obj.available && hoursArray.length < 5)
              hoursArray.push({
                [obj.hour.toString()]: {
                  date: date,
                  dayOfWeek: dayOfWeek,
                  dayLong: dateLong,
                },
              });
          });
        }
      }
      breakCounter++;
    } while (hoursArray.length < 5 && breakCounter < 10);
    setHoursState(hoursArray);
    setShowInnerLoader(false);
    _BookingContext?.setLoading(false);
  };

  useEffect(() => {
    _BookingContext.setLoading(true);
    if (_BookingContext.isFast) fetchFastHours();
  }, []);

  return (
    <TimePickContainer>
      <HeaderText>בחר שעה</HeaderText>
      {!isFast ? FetchHours() : null}
      {isFast ? (
        <ButtonsContainer>
          {hoursState.length > 0 ? (
            hoursState.map((hour, i) => {
              return (
                <HourButton
                  key={i}
                  type={"fast"}
                  hour={parseInt(Object.keys(hour)[0])} // @ts-ignore
                  date={Object.values(hour)[0].date} // @ts-ignore
                  dayOfWeek={Object.values(hour)[0].dayOfWeek} // @ts-ignore
                  dateLong={Object.values(hour)[0].dayLong}
                />
              );
            })
          ) : (
            <NoBookingText>
              <a>התספורת ממש דחופה?</a>
              <br />
              <a>לא נשארו תורים פנויים ליום הזה?</a>
              <br />
              <a>תמיד אפשרי לקבוע תור פרימיום מחוץ לשעות העבודה!</a>
              <br />
              <a>לתיאום - </a>
              <a href="tel:+972524597306">0524597306</a>
            </NoBookingText>
          )}
        </ButtonsContainer>
      ) : null}
      {showInnerLoader ? <LoaderInner /> : null}
    </TimePickContainer>
  );
};
