import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookMedical,
  faCalendarTimes,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logoImage from "./../../media/logoWhite.webp";
import styled, { keyframes } from "styled-components";
import fadeIn from "react-animations/lib/fadeIn";
import Div100vh from "react-div-100vh";
import { AnimationOnScroll } from "react-animation-on-scroll";

// Animations START

const fadeInAnimation = keyframes`${fadeIn}`;

// Animation END
// Styled-Components START

const NavStyled = styled(Div100vh)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  background: #000000ba;
  z-index: 98;
  backdrop-filter: blur(5px);
  animation: 0.5s ${fadeInAnimation};

  a {
    font-size: 1.2em;
    font-family: hebrewExtraBold;
    color: #fff;
  }

  svg {
    font-size: 1.3em;
  }

  li {
    margin: 25px;
  }

  img {
    position: absolute;
    bottom: 5%;
    height: 65px;
    animation: 1.5s ${fadeInAnimation};
  }
`;

// Styled-Components END
// Interfaces & Types START

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

// Interfaces & Types END

const Nav = (props: Props) => {
  return (
    <NavStyled>
      <nav>
        <ul>
          <Link to="/">
            <AnimationOnScroll animateIn="animate__fadeInUp">
              <li onClick={() => props.setOpen(false)}>
                <FontAwesomeIcon icon={faHome} />
                <br />
                ראשי
              </li>
            </AnimationOnScroll>
          </Link>
          <Link to="/book">
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
              <li onClick={() => props.setOpen(false)}>
                <FontAwesomeIcon icon={faBookMedical} />
                <br />
                הזמנת תור
              </li>
            </AnimationOnScroll>
          </Link>
          <Link to="/delete">
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={200}>
              <li onClick={() => props.setOpen(false)}>
                <FontAwesomeIcon icon={faCalendarTimes} />
                <br />
                מחיקת תור
              </li>
            </AnimationOnScroll>
          </Link>
        </ul>
      </nav>
      <img src={logoImage} alt={"Logo Image"} />
    </NavStyled>
  );
};

export default Nav;
