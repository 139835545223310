import React from "react";
import styled from "styled-components";

const LinesDiv = styled.div<{
  transform: string | undefined;
  bottom: string | undefined;
  color: string | undefined;
}>`
  position: absolute;
  bottom: ${(props) => props.bottom ?? "-25vh"};
  height: max-content;
  width: 100vw;
  z-index: -3;
  transform: ${(props) => props.transform ?? "scale(1.5) rotate(20deg)"};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 120%;
    width: 0%;
    background-color: #000;
    animation: lineAnim 4s infinite;
    z-index: -1;
  }

  & #hideTop {
    position: absolute;
    right: 0;
    top: -120px;
    height: 130px;
    width: 100%;
    background-color: #000;
  }

  & #waveShape {
    position: relative;
    filter: ${(props) => props.color ?? "drop-shadow(0 0 20px #fff)"};
    z-index: -3;
  }
`;

interface Props {
  transform?: string;
  bottom?: string;
  color?: string;
}

export const CoolLine = (props: Props) => {
  return (
    <>
      <LinesDiv
        transform={props.transform}
        bottom={props.bottom}
        color={props.color}
      >
        <div id="hideTop"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          id="waveShape"
        >
          <path
            fill="#000"
            fillOpacity="1"
            stroke="1px solid #fff"
            d="M0,160L24,138.7C48,117,96,75,144,69.3C192,64,240,96,288,96C336,96,384,64,432,53.3C480,43,528,53,576,90.7C624,128,672,192,720,234.7C768,277,816,299,864,277.3C912,256,960,192,1008,144C1056,96,1104,64,1152,85.3C1200,107,1248,181,1296,181.3C1344,181,1392,107,1416,69.3L1440,32L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
          />
        </svg>
      </LinesDiv>
    </>
  );
};
