import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

// Styled START

const LoaderInnerView = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  display: grid;
  place-items: center;
  border-radius: 10px;
  z-index: 1350;
`;

// Styled END

export const LoaderInner = () => {
  return (
    <LoaderInnerView>
      <CircularProgress />
    </LoaderInnerView>
  );
};
