import React, { useContext, useEffect, useState } from "react";
import { BookingContext, stagesStrings } from "../BookingContainer";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { HeaderText, SwiperDivStyled } from "./BarberPickStage";

import hairImage1 from "../../../../media/haircutTypes/1.webp";
import hairImage2 from "../../../../media/haircutTypes/2.webp";
import hairImage3 from "../../../../media/haircutTypes/3.webp";
import hairImage4 from "../../../../media/haircutTypes/4.webp";
import hairImage5 from "../../../../media/haircutTypes/5.webp";
import hairImage6 from "../../../../media/haircutTypes/6.webp";
import hairImage7 from "../../../../media/haircutTypes/7.jpg";

import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import ModalConfirmation from "../../../Utils/ModalConfirmation";

// Styled Components START

const HairstyleContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 20px;
`;

const SwiperStyled = styled(Swiper)`
  width: 100%;
  height: 310px;

  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 0 !important;
  }

  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #000000;
  }

  .swiper-pagination-bullet {
    background-color: #1c9fff !important;
    width: 10px !important;
    height: 10px !important;
    margin: 5px !important;
  }
`;

const HairstyleDivStyled = styled.div<{
  slideImage: string;
  clipPath: string;
  marginBottom?: string;
}>`
  width: 350px;
  height: 200px;
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.slideImage})` ?? ""};
  clip-path: ${(props) => props.clipPath ?? ""};
  display: grid;
  place-items: center;
`;

const HairstyleInfoStyled = styled.div`
  text-align: center;

  h1 {
    font-family: hebrewExtraBold;
    font-size: 20px;
    text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 10px rgb(0, 0, 0),
      2px 2px 2px rgba(206, 89, 55, 0);
    padding: 0 10px;
  }
  a {
    text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 10px rgb(0, 0, 0),
      2px 2px 2px rgba(206, 89, 55, 0);
    font-size: 14px;
  }
`;

// Styled Components END

const HairstylePickStage = () => {
  const _BookingContext = useContext(BookingContext);

  const [displayModal, setDisplayModal] = useState<boolean>(false);

  // Firebase Related
  const hairstyleTypesRef = doc(
    useFirestore(),
    `Barbers/${_BookingContext.userPicks.barber}/Data/Type`
  );
  const { status, data } = useFirestoreDocData(hairstyleTypesRef, {
    suspense: false,
  });

  const handleBarberPick = (hairstylePick, label) => {
    _BookingContext?.setHairstylePick({
      hairstyleDuration: hairstylePick / 5,
      hairstyleLabel: label,
    });
    if (_BookingContext.userPicks.barber === "Drory")
      return setDisplayModal(true);
    else if (!_BookingContext.isFast) {
      _BookingContext.setExtraPick(false);
      _BookingContext?.setStage({
        num: stagesStrings[2].num,
        str: stagesStrings[2].str,
      });
    } else {
      _BookingContext.setExtraPick(false);
      _BookingContext?.setStage({
        num: stagesStrings[3].num,
        str: stagesStrings[3].str,
      });
    }
  };

  // useEffect
  useEffect(() => {
    _BookingContext?.setProgress(25);
  }, []);

  useEffect(() => {
    status === "loading"
      ? _BookingContext?.setLoading(true)
      : _BookingContext?.setLoading(false);
  }, [status]);

  return (
    <>
      {displayModal ? (
        <ModalConfirmation
          modalTitle={"הודעת מערכת"}
          setDisplayModal={setDisplayModal}
          isFast={_BookingContext.isFast}
        />
      ) : null}
      {_BookingContext?.isLoading ? null : (
        <HairstyleContainer>
          <HeaderText>בחר תספורת</HeaderText>
          <SwiperDivStyled>
            <SwiperStyled
              direction={"vertical"}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              slidesPerView={1.5}>
              {_BookingContext?.userPicks.barber === "Drory" ? (
                  <>
                    <SwiperSlide
                        onClick={() =>
                            handleBarberPick(data?.Option0[1], data?.Option0[0])
                        }>
                      <HairstyleDivStyled
                          slideImage={hairImage3}
                          clipPath={"polygon(0 0, 100% 0%, 100% 95%, 0% 100%)"}>
                        <HairstyleInfoStyled>
                          <h1>{data?.Option0[0]}</h1>
                          <a>
                            {/*<span>{data?.Option0[1]} דק׳ | </span>*/}
                            <span>עלות - ₪{data?.Option0[2]}</span>
                          </a>
                        </HairstyleInfoStyled>
                      </HairstyleDivStyled>
                    </SwiperSlide>
                  </>
              ) : (
                  <>
                  <SwiperSlide
                    onClick={() =>
                      handleBarberPick(data?.Option0[1], data?.Option0[0])
                    }>
                    <HairstyleDivStyled
                      slideImage={hairImage1}
                      clipPath={"polygon(0 0, 100% 0%, 100% 95%, 0% 100%)"}>
                      <HairstyleInfoStyled>
                        <h1>{data?.Option0[0]}</h1>
                        <a>
                          {/*<span>{data?.Option0[1]} דק׳ | </span>*/}
                          <span>עלות - ₪{data?.Option0[2]}</span>
                        </a>
                      </HairstyleInfoStyled>
                    </HairstyleDivStyled>
                  </SwiperSlide>
                  <SwiperSlide
                    onClick={() =>
                      handleBarberPick(data?.Option1[1], data?.Option1[0])
                    }>
                    <HairstyleDivStyled
                      slideImage={hairImage2}
                      clipPath={"polygon(0 5%, 100% 0%, 100% 100%, 0 95%)"}>
                      <HairstyleInfoStyled>
                        <h1>{data?.Option1[0]}</h1>
                        <a>
                          {/*<span>{data?.Option1[1]} דק׳ | </span>*/}
                          <span>עלות - ₪{data?.Option1[2]}</span>
                        </a>
                      </HairstyleInfoStyled>
                    </HairstyleDivStyled>
                  </SwiperSlide>
                  </>
                )}
              <SwiperSlide
                onClick={() =>
                  handleBarberPick(data?.Option2[1], data?.Option2[0])
                }
              >
                <HairstyleDivStyled
                  slideImage={hairImage7}
                  clipPath={"polygon(0 0, 100% 5%, 100% 95%, 0 100%)"}
                >
                  <HairstyleInfoStyled>
                    <h1>{data?.Option2[0]}</h1>
                    <a>
                      {/*<span>{data?.Option2[1]} דק׳ | </span>*/}
                      <span>עלות - ₪{data?.Option2[2]}</span>
                    </a>
                  </HairstyleInfoStyled>
                </HairstyleDivStyled>
              </SwiperSlide>
              {_BookingContext?.userPicks.barber === 'Drory' &&(
              <SwiperSlide
                onClick={() => {
                  _BookingContext?.setInformativeModalState({
                    show: true,
                    text: "להזמנת תור זה, צרו איתי קשר",
                  });
                }}
              >
                <HairstyleDivStyled
                  slideImage={hairImage4}
                  clipPath={"polygon(0 5%, 100% 0%, 100% 100%, 0 95%)"}
                >
                  <HairstyleInfoStyled>
                    <h1>{data?.Option3[0]}</h1>
                    <a>
                      {/*<span>{data?.Option3[1]} דק׳ | </span>*/}
                      <span>עלות - ₪{data?.Option3[2]}</span>
                    </a>
                  </HairstyleInfoStyled>
                </HairstyleDivStyled>
              </SwiperSlide>
                )}
              <SwiperSlide
                onClick={() =>
                  handleBarberPick(data?.Option4[1], data?.Option4[0])
                }
              >
                <HairstyleDivStyled
                  slideImage={hairImage5}
                  clipPath={"polygon(0 0, 100% 5%, 100% 95%, 0 100%)"}
                >
                  <HairstyleInfoStyled>
                    <h1>{data?.Option4[0]}</h1>
                    <a>
                      {/*<span>{data?.Option4[1]} דק׳ | </span>*/}
                      <span>עלות - ₪{data?.Option4[2]}</span>
                    </a>
                  </HairstyleInfoStyled>
                </HairstyleDivStyled>
              </SwiperSlide>
              <SwiperSlide
                onClick={() =>
                  handleBarberPick(data?.Option5[1], data?.Option5[0])
                }
              >
                <HairstyleDivStyled
                  slideImage={hairImage6}
                  clipPath={"polygon(0 5%, 100% 0, 100% 100%, 0 100%)"}
                >
                  <HairstyleInfoStyled>
                    <h1>{data?.Option5[0]}</h1>
                    <a>
                      {/*<span>{data?.Option5[1]} דק׳ | </span>*/}
                      <span>עלות - ₪{data?.Option5[2]}</span>
                    </a>
                  </HairstyleInfoStyled>
                </HairstyleDivStyled>
              </SwiperSlide>
            </SwiperStyled>
          </SwiperDivStyled>
        </HairstyleContainer>
      )}
    </>
  );
};

export default HairstylePickStage;
