import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { HeaderText } from "./BarberPickStage";
import { BookingContext, stagesStrings } from "../BookingContainer";
import { Button, Form } from "react-bootstrap";
import { fadeInLeft } from "react-animations";
import {
  hebrewDays,
  UseDateHooks,
} from "../../AdminPage/SecondTopBar/SecondTopBar";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, functions } from "../../../../backend/firebase";
import { LoaderInner } from "../../AdminPage/_Utils/LoaderInner";
import { httpsCallable } from "firebase/functions";

// Animations START

const fadeInAnimation = keyframes`${fadeInLeft}`;

// Animation END
// Styled Components START

const ConfirmationStyled = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 20px;
  z-index: 2;
`;

const ConfirmationTextDiv = styled.div`
  padding-bottom: 10px;

  a {
    color: #fff;
    font-size: 24px;
  }

  span {
    font-family: hebrewBold;
  }
`;

const SubmitStyled = styled(Button)`
  width: 100%;
`;

const FormStyled = styled(Form)`
  animation: 0.5s ${fadeInAnimation};

  .form-control {
    border: 0 !important;
  }
`;

const ButtomStyled = styled(Button)`
  width: 100%;
  margin-top: 5px;
`;

// Styled Components END

const ConfirmationStage = () => {
  const _BookingContext = useContext(BookingContext);

  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [phoneInputState, setPhoneInputState] = useState<boolean>(false);
  const [codeInputState, setCodeInputState] = useState<boolean>(true);
  const [smsBtnState, setSmsBtnState] = useState<boolean>(true);
  const [verifyBtnState, setVerifyBtnState] = useState<boolean>(true);
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined);
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);

  const handleInputChange = (e, characters: number) => {
    e.target.value.length !== characters
      ? setSmsBtnState(true)
      : setSmsBtnState(false);
  };

  const handleSendSMS = async (e) => {
    setShowInnerLoader(true);
    if (
      _BookingContext.userPicks.time === "" ||
      _BookingContext.userPicks.barber === "" ||
      _BookingContext.userPicks.date.date === "" ||
      _BookingContext.userPicks.hairstyle.hairstyleLabel === ""
    ) {
      setShowInnerLoader(false);
      _BookingContext.setErrorModalState({
        show: true,
        text: "הפרטים שהזנת שגויים, רענן את הדף ונסה שוב",
      });
    }
    e.preventDefault();
    if (!isRendered) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: () => setIsRendered(true),
        },
        auth
      );
    }
    const phoneNumber: string = `+972${e.target[1].value}`;
    setPhone(e.target[1].value);
    setName(e.target[0].value);
    await signInWithPhoneNumber(
      auth,
      phoneNumber,
      (window as any).recaptchaVerifier
    )
      .then((result) => {
        setConfirmationResult(result);
        setPhoneInputState(true);
        setCodeInputState(false);
        setSmsBtnState(true);
        setVerifyBtnState(false);
        setShowInnerLoader(false);
      })
      .catch(() => {
        setShowInnerLoader(false);
        _BookingContext.setErrorModalState({ show: true });
      });
  };

  const handleSignIn = (e, confirmationResult) => {
    setShowInnerLoader(true);
    e.preventDefault();
    const code = e.target[2].value;
    confirmationResult
      .confirm(code)
      .then(async (result) => {
        await callAppointmentCreationHook(result);
      })
      .catch(() => {
        setShowInnerLoader(false);
        _BookingContext.setErrorModalState({
          show: true,
          text: "הוזן קוד שגוי",
        });
      });
  };

  const callAppointmentCreationHook = async (result: any) => {
    const typeDuration = _BookingContext.userPicks.extra
      ? _BookingContext.userPicks.hairstyle.hairstyleDuration + 1
      : _BookingContext.userPicks.hairstyle.hairstyleDuration;
    const _month: string = UseDateHooks(
      _BookingContext.userPicks.date.dateLong
    ).monthWithoutZero.toString();
    const _year: string = UseDateHooks(
      _BookingContext.userPicks.date.dateLong
    ).yearNumber.toString();
    const callCreateAppointmentFunction = httpsCallable(
      functions,
      "createAppointmentFunction"
    );
    await callCreateAppointmentFunction({
      userBookID: result.user.uid,
      firebaseDate: _BookingContext.userPicks.date.date,
      additionFinal: _BookingContext.userPicks.extra,
      userPicksBarber: _BookingContext.userPicks.barber,
      typeDuration: typeDuration,
      userPicksTime: _BookingContext.userPicks.time,
      userPicksHairstyleLabel:
        _BookingContext.userPicks.hairstyle.hairstyleLabel,
      userPhone: phone,
      userName: name,
      monthData: _month,
      yearData: _year,
      userPicksDayOfWeek: _BookingContext.userPicks.date.dayOfWeek,
    })
      .then(() => {
        setShowInnerLoader(false);
        auth.signOut();
        _BookingContext.setSuccessModalState({
          show: true,
          text: "התור נוצר בהצלחה, מסרון טקסט ישלח אליך בקרוב",
        });
        _BookingContext.setStage({
          num: stagesStrings[0].num,
          str: stagesStrings[0].str,
        });
      })
      .catch((error) => {
        setShowInnerLoader(false);
        auth.signOut();
        if (error.message === "010") {
          _BookingContext.setErrorModalState({
            show: true,
            text: `קוד - ${error.message} : מישהו תפס את התור שניה לפניך! נסה שוב`,
          });
        } else {
          _BookingContext.setErrorModalState({
            show: true,
            text: `קוד - ${error.message} : הייתה תקלה בעת יצירת התור, נסה שוב`,
          });
        }
        _BookingContext.setStage({
          num: stagesStrings[0].num,
          str: stagesStrings[0].str,
        });
      });
  };

  useEffect(() => {
    _BookingContext?.setProgress(100);
  }, []);

  return (
    <ConfirmationStyled>
      <HeaderText>סיכום הזמנה</HeaderText>
      <FormStyled
        onSubmit={(e) =>
          !smsBtnState ? handleSendSMS(e) : handleSignIn(e, confirmationResult)
        }
        validated={true}
      >
        <ConfirmationTextDiv>
          <a>
            ביום:
            <span> {hebrewDays[_BookingContext.userPicks.date.dayOfWeek]}</span>
          </a>
          <br />
          <a>
            בשעה: <span> {_BookingContext.userPicks.time}</span>
          </a>
          <br />
          <a>
            בתאריך: <span> {_BookingContext.userPicks.date.date}</span>
          </a>
          <br />
          <a>
            תוספות:
            <span> {_BookingContext.userPicks.extra ? "שעווה" : "ללא"}</span>
          </a>
        </ConfirmationTextDiv>
        <Form.Group className="mb-2" controlId="formName">
          <Form.Control type="text" placeholder="הכנס שם מלא" required />
        </Form.Group>

        <Form.Group className="mb-2" controlId="formPhone">
          <Form.Control
            type="tel"
            placeholder="הכנס מס׳ פלאפון"
            required
            disabled={phoneInputState}
            onChange={(e) => handleInputChange(e, 10)}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCode">
          <Form.Control
            type="tel"
            placeholder="הכנס קוד"
            disabled={codeInputState}
            onChange={(e) => handleInputChange(e, 10)}
            required
          />
        </Form.Group>
        <div id="sign-in-button" />
        <SubmitStyled
          variant="primary"
          type="submit"
          id="signInButton"
          disabled={smsBtnState}
        >
          שלח סמס
        </SubmitStyled>
        <ButtomStyled disabled={verifyBtnState} type="submit">
          אמת קוד
        </ButtomStyled>
      </FormStyled>
      {showInnerLoader ? <LoaderInner /> : null}
    </ConfirmationStyled>
  );
};

export default ConfirmationStage;
