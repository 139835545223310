import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AppMainContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons/faUserLock";
import { Button, Form } from "react-bootstrap";
import fadeIn from "react-animations/lib/fadeIn";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, db, functions } from "../../backend/firebase";
import Div100vh from "react-div-100vh";
import { LoaderInner } from "./AdminPage/_Utils/LoaderInner";
import { doc, getDoc } from "firebase/firestore";
import { DeleteItem } from "./_parts/Delete_Item";
import { httpsCallable } from "firebase/functions";
import { UseDateHooks } from "./AdminPage/SecondTopBar/SecondTopBar";
import { MaintananceMode } from "./BookingPage/Stages/_Utils/MaintananceMode";

const fadeInAnimation = keyframes`${fadeIn}`;

const DeleteContainerView = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: #fff;
  overflow: hidden;
  touch-action: none;
`;

const FormStyled = styled(Form)`
  animation: 0.5s ${fadeInAnimation};

  .form-control {
    border: 0 !important;
    box-shadow: 0 0 15px #e6e6e6;
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 5px;
`;

const ConfirmationTextDiv = styled.div`
  padding-bottom: 10px;
  display: grid;
  place-items: center;
  grid-auto-flow: column;

  h1 {
    color: #ffffff;
    font-size: 30px;
    font-family: hebrewLight;
    text-align: center;
    margin: 0;
  }

  path {
    color: #ffffff !important;
  }

  #userIcon {
    font-size: 30px;
  }
`;

const SubmitStyled = styled(Button)`
  width: 100%;
`;

const ItemsContainer = styled.div`
  width: 100%;
  padding: 0 50px;
  display: grid;
  place-items: center;
  height: max-content;
  max-height: 300px;
  overflow-y: auto;
`;

const NoBookingText = styled.h3`
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-family: hebrewLight;
`;

// Styled END

export const DeleteContainer = () => {
  const _AppMainContext = useContext(AppMainContext);

  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [phoneInputState, setPhoneInputState] = useState<boolean>(false);
  const [codeInputState, setCodeInputState] = useState<boolean>(true);
  const [smsBtnState, setSmsBtnState] = useState<boolean>(true);
  const [verifyBtnState, setVerifyBtnState] = useState<boolean>(true);
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined);
  const [shouldLoad, setShouldLoad] = useState<boolean>(false);
  const [displayLogin, setDisplayLogin] = useState<boolean>(true);
  const [booksData, setBooksData] = useState<any>([]);
  const [uid, setUid] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string | undefined>(undefined);
  const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false);

  const handleInputChange = (e, characters: number) => {
    e.target.value.length !== characters
      ? setSmsBtnState(true)
      : setSmsBtnState(false);
  };

  const handleSubmit = async (e) => {
    setShouldLoad(true);
    e.preventDefault();
    if (!isRendered) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: () => setIsRendered(true),
        },
        auth
      );
    }
    const phoneNumber: string = `+972${e.target[0].value}`;
    setUserPhone(e.target[0].value);
    await signInWithPhoneNumber(
      auth,
      phoneNumber,
      (window as any).recaptchaVerifier
    )
      .then((result) => {
        setConfirmationResult(result);
        setPhoneInputState(true);
        setCodeInputState(false);
        setSmsBtnState(true);
        setVerifyBtnState(false);
        setShouldLoad(false);
      })
      .catch(() => {
        _AppMainContext?.setErrorModalState({ show: true });
      });
  };

  const handleSignIn = (e, confirmationResult) => {
    setShouldLoad(true);
    e.preventDefault();
    const code = e.target[1].value;
    confirmationResult
      .confirm(code)
      .then(async (result) => {
        await DataFetch(result.user.uid);
      })
      .catch(() => {
        setShouldLoad(false);
        _AppMainContext?.setErrorModalState({
          show: true,
          text: "הוזן קוד שגוי",
        });
      });
  };

  async function DataFetch(uid: string) {
    setUid(uid);
    const dayHook = UseDateHooks();
    const today: unknown = {
      date: dayHook.fullFirebaseDate,
      hour: dayHook.hour,
    };
    await httpsCallable(
      functions,
      "deleteOldAppointmentsFunction"
    )({ todayData: today }).then(() => {
      const array: unknown[] = [];
      getDoc(doc(db, `Users/${uid}`)).then(async (doc) => {
        const activeData = doc.data()?.Active;
        Object.entries(activeData).map((dates) => array.push(dates));
        setBooksData(array);
        setDisplayLogin(false);
        setShouldLoad(false);
      });
    });
  }

  useEffect(() => {
    setShouldLoad(false);
    _AppMainContext?.setCurrentPage("Home");

    const fetchData = async () => {
      setShouldLoad(true);
      await getDoc(doc(db, "Barbers/Drory/Data/maintenance")).then((result) => {
        setMaintenanceMode(result.data()?.status);
      });
      setShouldLoad(false);
    };
    fetchData();
  }, []);

  return (
    <Div100vh>
      {maintenanceMode ? <MaintananceMode /> : null}
      <DeleteContainerView>
        {displayLogin ? (
          <FormStyled
            onSubmit={(e) =>
              !smsBtnState
                ? handleSubmit(e)
                : handleSignIn(e, confirmationResult)
            }
            validated={true}
          >
            <ConfirmationTextDiv>
              <h1>כניסת משתמש</h1>
              <FontAwesomeIcon icon={faUserLock} id="userIcon" />
            </ConfirmationTextDiv>
            <Form.Group className="mb-2" controlId="formPhone">
              <Form.Control
                type="tel"
                placeholder="הכנס מס׳ פלאפון"
                required
                disabled={phoneInputState}
                onChange={(e) => handleInputChange(e, 10)}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formCode">
              <Form.Control
                type="tel"
                placeholder="הכנס קוד"
                disabled={codeInputState}
                onChange={(e) => handleInputChange(e, 10)}
                required
              />
            </Form.Group>
            <div id="sign-in-button" />
            <SubmitStyled
              variant="primary"
              type="submit"
              id="signInButton"
              disabled={smsBtnState}
            >
              שלח סמס
            </SubmitStyled>
            <ButtonStyled disabled={verifyBtnState} type="submit">
              אמת קוד
            </ButtonStyled>
          </FormStyled>
        ) : (
          <ItemsContainer>
            {booksData.length > 0 ? (
              booksData.map((book) => {
                return book[1].map((bookInner, i) => {
                  return (
                    <DeleteItem
                      key={i}
                      date={book[0]}
                      hour={bookInner}
                      uid={uid}
                      setShouldLoad={setShouldLoad}
                      phoneNumber={userPhone!}
                    />
                  );
                });
              })
            ) : (
              <NoBookingText>אין תורים פעילים</NoBookingText>
            )}
          </ItemsContainer>
        )}
      </DeleteContainerView>
      {shouldLoad ? <LoaderInner /> : null}
    </Div100vh>
  );
};
