import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "react-bootstrap";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./backend/firebase";
import { ErrorModal } from "./components/Utils/ErrorModal";

class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  { error: any; errorInfo: any }
> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <ErrorModal modalState={true} setModalState={() => true} />
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ReactDOM.render(
  <ThemeProvider dir={"rtl"}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </FirebaseAppProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

reportWebVitals();
