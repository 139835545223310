import React, { useContext } from "react";
import Button from "@mui/material/Button";
import styled, { keyframes } from "styled-components";
import {
  BookingContext,
  StagesInterface,
  stagesStrings,
} from "../../BookingContainer";
import fadeInUp from "react-animations/lib/fadeInUp";
import { DailyHoursInterface } from "../TimePickStage";
import { beautifyHourHook } from "../../../AdminPage/_Pages/_Parts/Appointment_Item";
import { hebrewDays } from "../../../AdminPage/SecondTopBar/SecondTopBar";

// Animations START

const fadeInUpAnimation = keyframes`${fadeInUp}`;

// Animations END

// Styled Components START

const HourButtonContainer = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

const ButtonStyled = styled(Button)``;

const HourTextStyled = styled.a`
  font-size: 28px;
  font-family: hebrewBold;
  padding: 0 15px;
  color: #fff;
`;

const RegularBookingsStyled = styled.div``;
const FiveBookingsStyled = styled.div``;

// Styled Components END

interface Props {
  hour: number;
  date?: string;
  dayOfWeek?: number;
  dateLong?: Date;
  type: "normal" | "fast";
}

const HourButton = (props: Props) => {
  const _BookingContext = useContext(BookingContext);
  const handleBarberPick = (stage: StagesInterface) => {
    _BookingContext?.setStage(stage);
    _BookingContext.setTimePick(beautifyHourHook(props.hour.toString()));

    if (props.type === "fast") {
      _BookingContext.setDatePick({
        // @ts-ignore
        date: props.date, // @ts-ignore
        dayOfWeek: props.dayOfWeek, // @ts-ignore
        dateLong: props.dateLong,
      });
    }
  };

  return (
    <HourButtonContainer>
      <ButtonStyled variant={"contained"}>
        {props.type === "normal" ? (
          <RegularBookingsStyled
            onClick={() =>
              handleBarberPick({
                num: stagesStrings[4].num,
                str: stagesStrings[4].str,
              })
            }
          >
            <HourTextStyled>{`${beautifyHourHook(
              props.hour.toString()
            )} - פנוי`}</HourTextStyled>
          </RegularBookingsStyled>
        ) : null}
        {props.type === "fast" ? (
          <FiveBookingsStyled
            onClick={() =>
              handleBarberPick({
                num: stagesStrings[4].num,
                str: stagesStrings[4].str,
              })
            }
          >
            <a>
              יום {hebrewDays[props.dayOfWeek ?? 0]} - {props.date}{" "}
            </a>
            <br />
            <HourTextStyled>
              {beautifyHourHook(props.hour.toString())} - פנוי
            </HourTextStyled>
          </FiveBookingsStyled>
        ) : null}
      </ButtonStyled>
    </HourButtonContainer>
  );
};

export default HourButton;
