import React, { useState } from "react";
import styled from "styled-components";
import { Squash as Hamburger } from "hamburger-react";
import { AdminColors } from "../_Utils/colors";
import { AdminNav } from "./AdminNav";

const AdminNavContainerView = styled.div``;

const HeaderStyled = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  z-index: 99;
  place-items: center flex-start;
  background-color: ${AdminColors.NAV_BLUE};
`;

const HeaderText = styled.a`
  font-family: hebrewExtraBold;
  font-size: 25px;
  color: #fff;
  margin-right: 15px;
`;

const HamburgerContainer = styled.div`
  padding-left: 15px;

  .hamburger-react {
    transform: scale(0.8);
    color: #fff;
  }
`;

export const AdminNavContainer = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <AdminNavContainerView>
      <HeaderStyled>
        <HeaderText>מערכת ניהול תורים</HeaderText>
        <HamburgerContainer>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </HamburgerContainer>
      </HeaderStyled>
      {isOpen ? <AdminNav setOpen={setOpen} /> : null}
    </AdminNavContainerView>
  );
};
