import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AdminContext } from "../AdminContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Vacations_Item } from "./_Parts/Vacations_Item";
import { AdminColors } from "../_Utils/colors";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../backend/firebase";
import { AnimationOnScroll } from "react-animation-on-scroll";
import moment from "moment";

// Styled START

const VacationsView = styled.div`
  padding: 20px;
`;

const BoxStyled = styled(Box)`
  .css-1aquho2-MuiTabs-indicator {
    background-color: ${AdminColors.NAV_BLUE} !important;
    height: 1px !important;
  }

  .css-19kzrtu {
    padding: 0 10px;
    max-height: 300px;
    overflow-y: auto;
  }
`;

const TabStyled = styled(Tab)`
  font-family: hebrewLight !important;
  font-size: 16px !important;
  color: #000 !important;
`;

const NoDataText = styled.h3`
  font-family: hebrewLight;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

// Styled END

// Hooks START

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Hooks END

export const Vacations = () => {
  const _AdminContext = useContext(AdminContext);
  const [tabValue, setTabValue] = React.useState(0);
  const [breakDays, setBreakDays] = useState<Array<any>>([]);
  const [breakDates, setBreakDates] = useState<Array<any>>([]);
  const [vacations, setVacations] = useState<Array<any>>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    _AdminContext.setIsLoading(true);
    const getData = async () => {
      await onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data/Break-Vacation/`),
        (snapshot) => {
          // @ts-ignore
          setVacations(Object.entries(snapshot.data()));
        }
      );
      await onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data/Breaks-Days/`),
        (snapshot) => {
          // @ts-ignore
          setBreakDays(Object.entries(snapshot.data()));
        }
      );
      await onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data/Breaks-Dates/`),
        (snapshot) => {
          // @ts-ignore
          setBreakDates(Object.entries(snapshot.data()));
        }
      );
      _AdminContext.setIsLoading(false);
    };
    getData().catch(() => {
      _AdminContext.setErrorModalState({ show: true });
      _AdminContext.setIsLoading(false);
    });
  }, [setBreakDays, setBreakDates, setVacations]);

  return (
    <VacationsView id="scrollParent">
      <AnimationOnScroll
        animateIn="animate__fadeIn"
        scrollableParentSelector={"#scrollParent"}
      >
        <div>
          <BoxStyled sx={{ width: "max-content", margin: "0 auto" }}>
            <BoxStyled sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <TabStyled label="הפסקה קבועה" {...a11yProps(0)} />
                <TabStyled label="הפסקה ח״פ" {...a11yProps(1)} />
                <TabStyled label="חופשה" {...a11yProps(2)} />
              </Tabs>
            </BoxStyled>
            <TabPanel value={tabValue} index={0}>
              {breakDays.length > 0 ? (
                breakDays.map((item) => {
                  return item[1].map((innerItem, i) => (
                    <Vacations_Item
                      key={i}
                      breakDays={[item[0], innerItem]}
                      setTabValue={setTabValue}
                      tab={tabValue}
                    />
                  ));
                })
              ) : (
                <NoDataText>אין מידע</NoDataText>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {breakDates.length > 0 ? (
                breakDates.map((item) => {
                  return item[1].map((innerItem, i) => (
                    <Vacations_Item
                      key={i}
                      breakDates={[item[0], innerItem]}
                      setTabValue={setTabValue}
                      tab={tabValue}
                    />
                  ));
                })
              ) : (
                <NoDataText>אין מידע</NoDataText>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {vacations.length > 0 ? (
                vacations.map((item, i) => (
                  <Vacations_Item
                    key={i}
                    vacations={item}
                    setTabValue={setTabValue}
                    tab={tabValue}
                  />
                ))
              ) : (
                <NoDataText>אין מידע</NoDataText>
              )}
            </TabPanel>
          </BoxStyled>
        </div>
      </AnimationOnScroll>
    </VacationsView>
  );
};
