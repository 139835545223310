import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import { beautifyHourHook } from "../_Pages/_Parts/Appointment_Item";
import { LoaderInner } from "./LoaderInner";
import {
  BoxStyled,
  CloseButtonStyled,
  DateContainerStyled,
  HeaderSection,
  ModalHeader,
  ModalStyled,
  UseCreateHours,
} from "./AppointmentCreation";
import { Button, Form } from "react-bootstrap";
import { AdminContext, BreaksModalInterface } from "../AdminContainer";
import { englishDays, hebrewDays } from "../SecondTopBar/SecondTopBar";
import DatePicker, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../backend/firebase";
import moment from "moment";
import uuid from "react-uuid";

//

registerLocale("he", he);

// Styled START

const BreaksCreationView = styled.div``;

// Styled END
// START HOOKS

const getDurationHook = (numMin, numMax) => {
  let cnt: number = 1,
    durationCounter: number = 0;
  for (let i = numMin; i < numMax; i += 5) {
    if (numMin + cnt * 100 - i === 40) {
      i = numMin + cnt * 100;
      cnt++;
    }
    durationCounter++;
  }
  if ((numMax - numMin) % 100 === 0) return --durationCounter;
  return durationCounter;
};

// END HOOKS

interface Props {
  modalState: BreaksModalInterface;
  setModalState: Dispatch<SetStateAction<BreaksModalInterface>>;
}

export const BreaksCreation = (props: Props) => {
  const _AdminContext = useContext(AdminContext);
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);
  const [dayName, setDayName] = useState<string | undefined>(undefined);
  const [startHour, setStartHour] = useState<number | undefined>(undefined);
  const [endHour, setEndHour] = useState<number | undefined>(undefined);
  const [firstDate, setFirstDate] = useState<Date | undefined>(undefined);
  const [secondDate, setSecondDate] = useState<Date | undefined>(undefined);

  const handleSubmit = async (e) => {
    getDurationHook(startHour, endHour);
    e.preventDefault();
    setShowInnerLoader(true);
    if (props.modalState.type === "breaks-day") {
      // @ts-ignore
      if (startHour >= endHour) {
        setShowInnerLoader(false);
        return _AdminContext.setErrorModalState({
          show: true,
          text: "שעת תחילה גבוהה משעת סיום או שווה לה",
        });
      } else {
        // @ts-ignore
        const mixedHour: string = `${startHour}/${getDurationHook(
          startHour,
          endHour
        )}`;
        await updateDoc(
          doc(db, `Barbers/${_AdminContext.adminName}/Data/Breaks-Days`),
          {
            // @ts-ignore
            [dayName]: arrayUnion(mixedHour),
          }
        )
          .then(() => {
            setShowInnerLoader(false);
            _AdminContext.setSuccessModalState({
              show: true,
              text: "ההפסקה נוצרה בהצלחה",
            });
          })
          .catch(() => {
            setShowInnerLoader(false);
            _AdminContext.setErrorModalState({ show: true });
          });
      }
    } else if (props.modalState.type === "breaks-date") {
      // @ts-ignore
      if (startHour >= endHour) {
        setShowInnerLoader(false);
        return _AdminContext.setErrorModalState({
          show: true,
          text: "שעת תחילה גבוהה משעת סיום או שווה לה",
        });
      } else {
        // @ts-ignore
        const mixedHour: string = `${startHour}/${getDurationHook(
          startHour,
          endHour
        )}`;
        const firebaseDate: string = moment(firstDate).format("DD-MM-YYYY");
        await updateDoc(
          doc(db, `Barbers/${_AdminContext.adminName}/Data/Breaks-Dates`),
          {
            // @ts-ignore
            [firebaseDate]: arrayUnion(mixedHour),
          }
        )
          .then(() => {
            setShowInnerLoader(false);
            _AdminContext.setSuccessModalState({
              show: true,
              text: "ההפסקה נוצרה בהצלחה",
            });
          })
          .catch(() => {
            setShowInnerLoader(false);
            _AdminContext.setErrorModalState({ show: true });
          });
      }
    } else if (props.modalState.type === "vacations") {
      // @ts-ignore
      if (firstDate === undefined || secondDate === undefined) {
        setShowInnerLoader(false);
        return _AdminContext.setErrorModalState({
          show: true,
          text: "נא לבחור את שני התאריכים",
        });
      }
      if (firstDate >= secondDate) {
        setShowInnerLoader(false);
        return _AdminContext.setErrorModalState({
          show: true,
          text: "תאריך תחילה גבוהה מתאריך סיום או שווה לו",
        });
      } else {
        // @ts-ignore
        const firebaseFirstDate: string =
          moment(firstDate).format("DD-MM-YYYY");
        const firebaseSecondDate: string =
          moment(secondDate).format("DD-MM-YYYY");
        await updateDoc(
          doc(db, `Barbers/${_AdminContext.adminName}/Data/Break-Vacation`),
          {
            // @ts-ignore
            [uuid()]: [firebaseFirstDate, firebaseSecondDate],
          }
        )
          .then(() => {
            setShowInnerLoader(false);
            _AdminContext.setSuccessModalState({
              show: true,
              text: "ההפסקה נוצרה בהצלחה",
            });
          })
          .catch(() => {
            setShowInnerLoader(false);
            _AdminContext.setErrorModalState({ show: true });
          });
      }
    }
    return;
  };

  return (
    <BreaksCreationView>
      <ModalStyled
        open={props.modalState.show}
        onClose={() => props.setModalState({ show: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxStyled>
          <Form onSubmit={(e) => handleSubmit(e)} validated>
            <HeaderSection>
              <ModalHeader>הפסקה/חופשה חדשה</ModalHeader>
              <CloseButtonStyled
                onClick={() => props.setModalState({ show: false })}
              />
            </HeaderSection>
            {props.modalState.type !== "vacations" ? (
              <div>
                {props.modalState.type === "breaks-day" ? (
                  <Form.Select
                    aria-label="Default select example"
                    required
                    onChange={(e) => {
                      setDayName(e.target.value);
                    }}
                    value={dayName}
                  >
                    <option value="" selected disabled>
                      בחר יום
                    </option>
                    {hebrewDays.map((day, i) => (
                      <option key={i} value={englishDays[i]}>
                        {day}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <DateContainerStyled style={{ marginTop: "0" }}>
                    <DatePicker
                      selected={firstDate}
                      popperPlacement={"bottom"}
                      placeholderText={"בחר תאריך"}
                      locale={he}
                      onChange={(date) => setFirstDate(date)}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </DateContainerStyled>
                )}
                <Form.Select
                  aria-label="Default select example"
                  required
                  onChange={(e) => {
                    setStartHour(parseInt(e.target.value));
                  }}
                  value={startHour}
                >
                  <option value="" selected disabled>
                    בחר שעת תחילה
                  </option>
                  {UseCreateHours().map((hour) => (
                    <option value={hour} key={hour}>
                      {beautifyHourHook(hour.toString())}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  aria-label="Default select example"
                  required
                  onChange={(e) => {
                    setEndHour(parseInt(e.target.value));
                  }}
                  value={endHour}
                >
                  <option value="" selected disabled>
                    בחר שעת סיום
                  </option>
                  {UseCreateHours().map((hour) => (
                    <option value={hour} key={hour}>
                      {beautifyHourHook(hour.toString())}
                    </option>
                  ))}
                </Form.Select>
              </div>
            ) : (
              <div>
                <DateContainerStyled style={{ marginTop: "0" }}>
                  <DatePicker
                    selected={firstDate}
                    popperPlacement={"bottom"}
                    placeholderText={"בחר תאריך תחילה"}
                    locale={he}
                    onChange={(date) => setFirstDate(date)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </DateContainerStyled>
                <DateContainerStyled
                  style={{ marginTop: "15px", marginBottom: "5px" }}
                >
                  <DatePicker
                    selected={secondDate}
                    popperPlacement={"bottom"}
                    placeholderText={"בחר תאריך סיום"}
                    locale={he}
                    onChange={(date) => setSecondDate(date)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </DateContainerStyled>
              </div>
            )}
            <Button type={"submit"}>צור חופשה/הפסקה</Button>
          </Form>
          {showInnerLoader ? <LoaderInner /> : null}
        </BoxStyled>
      </ModalStyled>
    </BreaksCreationView>
  );
};
