import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, CloseButton, Form } from "react-bootstrap";
import { AdminColors } from "./colors";
import DatePicker, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import moment from "moment";
import { DateContainer } from "../_Pages/_Appointments";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db, functions } from "../../../../backend/firebase";
import { AdminContext } from "../AdminContainer";
import { beautifyHourHook } from "../_Pages/_Parts/Appointment_Item";
import { LoaderInner } from "./LoaderInner";
import Select from "react-select";
import { UseDateHooks } from "../SecondTopBar/SecondTopBar";
import { httpsCallable } from "firebase/functions";

//
registerLocale("he", he);

// Styled START

const CreationModalView = styled.div`
  z-index: 10;
  width: 100%;
`;

export const ModalStyled = styled(Modal)`
  .MuiBox-root {
    width: 100%;
    height: max-content;
    text-align: center;
    background-color: ${AdminColors.BG_WHITE};
    box-shadow: 0 0 2.5px 0 #ebebeb, 0 10px 0 0 #ebebeb, 0 20px 0 0 #c8c8c8;
    border-radius: 10px;
    padding: 10px;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .form-select,
  input {
    border: none;
    outline: none;
    -webkit-box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb,
      0 10px 5px 0 #e1e1e1 !important;
    margin: 15px 0;
    padding: 10px;
    width: 100%;
    font-size: 20px;
    font-family: hebrewLight;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .react-datepicker__input-container input {
    margin: 0;
  }

  Button {
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 20px;
  }
`;

export const BoxStyled = styled(Box)`
  position: relative;
`;

export const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  display: grid;
`;

export const ModalHeader = styled.h3`
  font-family: hebrewLight;
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
`;

export const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 0;
  left: 5px;
  width: 6px !important;
  margin-top: 0 !important;
  font-size: 20px;
`;

export const DateContainerStyled = styled(DateContainer)`
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2.5em;
    height: 2.5em;
    padding: 5px 0;
  }
`;

// Styled END

export const UseCreateHours = () => {
  const hoursArray: number[] = [];
  const minHour = 700;
  const maxHour = 2200;
  let cnt = 1;
  for (let i = minHour; i < maxHour; i += 5) {
    if (minHour + cnt * 100 - i === 40) {
      i = minHour + cnt * 100;
      cnt++;
    }
    hoursArray.push(i);
  }
  return hoursArray;
};

interface UserInterface {
  value: string;
  label: string;
  name: string;
  phone: string;
}

interface Props {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
}

export const AppointmentCreation = (props: Props) => {
  const _AdminContext = useContext(AdminContext);
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);
  const [typesData, setTypesData] = useState<Array<any>>([]);
  const [userListDB, setUserListDB] = useState<Array<any>>([]);
  const [userList, setUserList] = useState<readonly UserInterface[]>([]);
  const [userChosen, setUserChosen] = useState<UserInterface | null>(null);
  const [typeChosen, setTypeChosen] = useState<any>();
  const [additionsChosen, setAdditionsChosen] = useState<any>();
  const [hourChosen, setHourChosen] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const callAppointmentCreationHook = async () => {
    const firebaseDate = moment(selectedDate).format("DD-MM-YYYY");
    const additionFinal: boolean = additionsChosen === "0" ? false : true;
    const typeDuration =
      typesData[typeChosen][1][1] / 5 + parseInt(additionsChosen);
    const typeLabel = typesData[typeChosen][1][0];

    const _year: string =
      selectedDate !== null
        ? UseDateHooks(selectedDate).yearNumber.toString()
        : "";
    const _month: string =
      selectedDate !== null
        ? UseDateHooks(selectedDate).monthWithoutZero.toString()
        : "";
    const callCreateAppointmentFunction = httpsCallable(
      functions,
      "createAppointmentFunction"
    );
    const dayOfWeek: number = selectedDate!.getDay();
    const beautifiedHour: string = beautifyHourHook(hourChosen);

    await callCreateAppointmentFunction({
      userBookID: userChosen!.value,
      firebaseDate: firebaseDate,
      additionFinal: additionFinal,
      userPicksBarber: _AdminContext.adminName,
      typeDuration: typeDuration,
      userPicksTime: beautifiedHour,
      userPicksHairstyleLabel: typeLabel,
      userPhone: userChosen!.phone,
      userName: userChosen!.name,
      monthData: _month,
      yearData: _year,
      userPicksDayOfWeek: dayOfWeek,
    })
      .then(() => {
        setShowInnerLoader(false);
        _AdminContext.setSuccessModalState({
          show: true,
          text: "התור נוצר בהצלחה",
        });
      })
      .catch((error) => {
        if (error.message === "010") {
          setShowInnerLoader(false);
          _AdminContext.setErrorModalState({
            show: true,
            text: `קוד - ${error.message} : קיים תור בשעה הזאת`,
          });
        } else {
          setShowInnerLoader(false);
          _AdminContext.setErrorModalState({
            show: true,
            text: `קוד - ${error.message} : הייתה תקלה בעת יצירת התור, נסה שוב`,
          });
        }
      });
  };

  const handleSubmit = async (event) => {
    setShowInnerLoader(true);
    event.preventDefault();
    event.stopPropagation();
    await callAppointmentCreationHook();
  };

  useEffect(() => {
    _AdminContext.setIsLoading(false);
    const fetchData = async () => {
      setShowInnerLoader(true);
      const typesFetch = await onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data`, "Type"),
        (snapshot) => {
          const data = snapshot.data();
          // @ts-ignore
          setTypesData(Object.entries(data).sort());
        }
      );
      const collectionRef = collection(db, "Users");
      const usersFetch = await getDocs(
        query(collectionRef, orderBy("Visited", "desc"))
      ).then((result) => {
        setUserListDB(result.docs);
      });
      await Promise.all([typesFetch, usersFetch]);
      setShowInnerLoader(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (userListDB.length > 0) {
      const list = userListDB.map((doc) => {
        return {
          value: doc.id,
          label: `${doc.data().Name} - ${doc.data().Phone}`,
          name: doc.data().Name,
          phone: doc.data().Phone,
        };
      });
      setUserList(list);
    }
  }, [userListDB]);

  return (
    <CreationModalView>
      <ModalStyled
        open={props.modalState}
        onClose={() => props.setModalState(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxStyled>
          <Form onSubmit={(e) => handleSubmit(e)} validated>
            <HeaderSection>
              <ModalHeader>תור חדש</ModalHeader>
              <CloseButtonStyled onClick={() => props.setModalState(false)} />
            </HeaderSection>
            <Form.Select
              aria-label="Default select example"
              required
              onChange={(e) => {
                setTypeChosen(e.target.value);
              }}
              value={typeChosen}
            >
              <option value="" selected disabled>
                בחר סוג תור
              </option>
              {typesData.length > 0
                ? typesData.map((type, i) => (
                    <option
                      value={i}
                      key={type}
                    >{`${type[1][0]} - ${type[1][1]} דק׳`}</option>
                  ))
                : null}
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              required
              onChange={(e) => {
                setAdditionsChosen(e.target.value);
              }}
              value={additionsChosen}
            >
              <option value="" selected disabled>
                בחר תוספת
              </option>
              <option value={"1"}>תוספת שעווה - 5 דק׳</option>
              <option value={"0"}>ללא תוספת</option>
            </Form.Select>
            <DateContainerStyled style={{ marginTop: "0" }}>
              <DatePicker
                selected={selectedDate}
                popperPlacement={"bottom"}
                placeholderText={"בחר תאריך"}
                locale={he}
                onChange={(date) => setSelectedDate(date)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </DateContainerStyled>
            <Form.Select
              aria-label="Default select example"
              required
              onChange={(e) => {
                setHourChosen(e.target.value);
              }}
              value={hourChosen}
            >
              <option value="" selected disabled>
                בחר שעה
              </option>
              {UseCreateHours().map((hour) => (
                <option value={hour} key={hour}>
                  {beautifyHourHook(hour.toString())}
                </option>
              ))}
            </Form.Select>
            <Select
              defaultValue={userChosen}
              placeholder={"בחר לקוח"}
              onChange={setUserChosen}
              options={userList}
            />
            <Button type={"submit"}>צור תור</Button>
          </Form>
          {showInnerLoader ? <LoaderInner /> : null}
        </BoxStyled>
      </ModalStyled>
    </CreationModalView>
  );
};
