import React from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { CircularProgress } from "@mui/material";
import { AdminColors } from "./colors";

const LoaderStyled = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  touch-action: none;
  background-color: ${AdminColors.BG_WHITE};
  display: grid;
  place-items: center;
  z-index: 100;
`;

export const AdminLoader = () => {
  return (
    <Div100vh>
      <LoaderStyled>
        <CircularProgress />
      </LoaderStyled>
    </Div100vh>
  );
};
