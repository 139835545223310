import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AdminColors } from "../../_Utils/colors";
import { englishDays, hebrewDays } from "../../SecondTopBar/SecondTopBar";
import { beautifyHourHook } from "./Appointment_Item";
import {
  arrayRemove,
  deleteField,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../../backend/firebase";
import { AdminContext } from "../../AdminContainer";

// Styled START

const VacationsItemView = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: max-content;
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;
  margin: 20px 0;
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  place-items: flex-start;
`;

export const RightSide = styled.div`
  text-align: right;
`;

export const ItemHeader = styled.a`
  padding-left: 5px;
  color: ${AdminColors.NAV_BLUE};
`;
export const ItemValue = styled.a`
  font-family: hebrewLight;
  padding-left: 5px;
  color: #000;
`;

// Styled END

interface Props {
  breakDays?: Array<string>;
  breakDates?: Array<string>;
  vacations?: Array<string>;
  setTabValue: Dispatch<SetStateAction<number>>;
  tab: number;
}

export const Vacations_Item = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [headerValue, setHeaderValue] = useState<string | undefined>(undefined);
  const [firstValue, setFirstValue] = useState<string | undefined>(undefined);
  const [secondValue, setSecondValue] = useState<number | string | undefined>(
    undefined
  );
  const [pathEnd, setPathEnd] = useState<string>("");

  const ADMIN_DATA_PATH = `Barbers/${_AdminContext.adminName}/Data`;

  const UseDataliseHook = () => {
    if (props.breakDays !== undefined) {
      setPathEnd("Breaks-Days");
      setHeaderValue(hebrewDays[englishDays.indexOf(props.breakDays[0])]);
      const hourSplit = props.breakDays[1].split("/");
      setFirstValue(beautifyHourHook(hourSplit[0]));
      setSecondValue(parseInt(hourSplit[1]) * 5);
    } else if (props.breakDates !== undefined) {
      setPathEnd("Breaks-Dates");
      setHeaderValue(props.breakDates[0]);
      const hourSplit = props.breakDates[1].split("/");
      setFirstValue(beautifyHourHook(hourSplit[0]));
      setSecondValue(parseInt(hourSplit[1]) * 5);
    } else if (props.vacations !== undefined) {
      setFirstValue(props.vacations[1][0]);
      setSecondValue(props.vacations[1][1]);
    }
  };

  const handleDelete = async () => {
    _AdminContext.setIsLoading(true);
    let daysOrDates;
    if (props.breakDays !== undefined) daysOrDates = props.breakDays;
    if (props.breakDates !== undefined) daysOrDates = props.breakDates;

    if (props.vacations !== undefined) {
      await updateDoc(doc(db, ADMIN_DATA_PATH, "Break-Vacation"), {
        [props.vacations[0]]: deleteField(),
      }).then(() => {
        _AdminContext.setIsLoading(false);
        _AdminContext.setSuccessModalState({
          show: true,
          text: "המחיקה בוצעה בהצלחה",
        });
      });
    } else if (
      props.breakDays !== undefined ||
      props.breakDates !== undefined
    ) {
      await getDoc(doc(db, `${ADMIN_DATA_PATH}/${pathEnd}`)).then(
        async (result) => {
          // @ts-ignore
          if (result.data()[daysOrDates[0]].length === 1) {
            await updateDoc(doc(db, `${ADMIN_DATA_PATH}/${pathEnd}`), {
              [daysOrDates[0]]: deleteField(),
            })
              .then(() => {
                _AdminContext.setIsLoading(false);
                _AdminContext.setSuccessModalState({
                  show: true,
                  text: "המחיקה בוצעה בהצלחה",
                });
              })
              .catch(() => {
                _AdminContext.setIsLoading(false);
                _AdminContext.setErrorModalState({
                  show: true,
                  text: "קרתה תקלה בעת מחיקה, נסה שוב",
                });
              });
          } else {
            await updateDoc(doc(db, `${ADMIN_DATA_PATH}/${pathEnd}`), {
              [daysOrDates[0]]: arrayRemove(daysOrDates[1]),
            })
              .then(() => {
                _AdminContext.setIsLoading(false);
                _AdminContext.setSuccessModalState({
                  show: true,
                  text: "המחיקה בוצעה בהצלחה",
                });
              })
              .catch(() => {
                _AdminContext.setIsLoading(false);
                _AdminContext.setErrorModalState({
                  show: true,
                  text: "קרתה תקלה בעת מחיקה, נסה שוב",
                });
              });
          }
        }
      );
    }
    if (props.tab !== 0) {
      props.setTabValue(0);
      props.setTabValue(props.tab);
    } else {
      props.setTabValue(1);
      props.setTabValue(props.tab);
    }
  };

  useEffect(() => {
    UseDataliseHook();
  }, []);

  return (
    <VacationsItemView>
      <InnerContainer>
        <RightSide>
          {!!props.breakDays || !!props.breakDates ? (
            <ItemHeader>{`${props.breakDays ? "ביום" : "בתאריך"} ${
              headerValue ?? "null"
            }:`}</ItemHeader>
          ) : null}
          <ItemValue>{`מ-${firstValue ?? "null"}`}</ItemValue>
          {props.breakDays || props.breakDates ? (
            <ItemValue>{`למשך-${secondValue ?? "null"} דק׳`}</ItemValue>
          ) : (
            <ItemValue>{`עד-${secondValue ?? "null"}`}</ItemValue>
          )}
        </RightSide>
        <div>
          <DeleteForeverIcon color={"error"} onClick={handleDelete} />
        </div>
      </InnerContainer>
    </VacationsItemView>
  );
};
