import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AdminContext } from "../AdminContainer";
import { Switch } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Button } from "react-bootstrap";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../../backend/firebase";

const AppointmentsMessagesView = styled.div`
  padding: 0 20px;
  height: 100%;
`;

const NoPermissionsText = styled.a`
  position: relative;
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  margin-top: 30px;
  color: #000;
  z-index: 9999;
`;

const AppointmentsContainer = styled.div`
  position: relative;
  display: grid;
  place-items: flex-start;
  grid-auto-flow: row;
  margin-top: 30px;
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;
  padding: 10px;
`;

const ToggleDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
`;

const ToggleLabel = styled.h3`
  font-family: hebrewLight;
  color: #000;
  font-size: 20px;
  margin: auto 0;
`;

const SaveButton = styled(Button)`
  margin-top: 10px;
  padding: 5px 0;
  width: 100%;

  a {
    color: #fff;
    font-family: hebrewLight;
    font-size: 20px;
  }
`;

const TextAreaStyled = styled.textarea`
  padding: 5px;
  appearance: none;
  border: 1px solid #e0e0e0;
  font-family: hebrewLight;
  width: 100%;
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const AppointmentsMessages = () => {
  const _AdminContext = useContext(AdminContext);
  const [checked, setChecked] = useState<boolean>(false);
  const [areaText, setAreaText] = useState<string>("");

  const handleUpdate = (_checked?: boolean) => {
    const innerChecked: boolean = _checked ?? checked;
    _AdminContext.setIsLoading(true);
    setChecked(innerChecked);
    const docRef = doc(
      db,
      `Barbers/${_AdminContext.adminName}/Data`,
      "Message"
    );
    updateDoc(docRef, {
      Checked: innerChecked,
      Message: areaText,
    })
      .then(() => {
        _AdminContext.setIsLoading(false);
        _AdminContext.setSuccessModalState({
          show: true,
        });
      })
      .catch(() => {
        _AdminContext.setIsLoading(false);
        _AdminContext.setErrorModalState({
          show: true,
          text: "קרתה תקלה בעת השמירה, נסה שוב",
        });
      });
  };

  useEffect(() => {
    if (_AdminContext.adminName === "Drory") {
      onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data`, "Message"),
        (snapshot) => {
          const data = snapshot.data();
          // @ts-ignore
          setChecked(data?.Checked);
          setAreaText(data?.Message);
          _AdminContext.setIsLoading(false);
        }
      );
    } else {
      _AdminContext.setIsLoading(false);
    }
  }, []);
  return (
    <AppointmentsMessagesView id="scrollParent">
      {_AdminContext.adminName === "Drory" ? (
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          scrollableParentSelector={"#scrollParent"}
        >
          <AppointmentsContainer>
            <ToggleDiv>
              <Switch
                checked={checked}
                onChange={(e) => handleUpdate(e.target.checked)}
              />
              <ToggleLabel>
                {`הודעת המערכת ${checked ? "מופעלת" : "כבויה"}`}
              </ToggleLabel>
            </ToggleDiv>
            <TextAreaStyled
              cols={30}
              rows={7}
              placeholder="טקסט ההודעה"
              value={areaText}
              onChange={(e) => setAreaText(e.target.value)}
            />
            <SaveButton onClick={() => handleUpdate()}>
              <a>שמור</a>
            </SaveButton>
          </AppointmentsContainer>
        </AnimationOnScroll>
      ) : (
        <NoPermissionsText>אין לך הרשאות לצפות בהודעת מערכת</NoPermissionsText>
      )}
    </AppointmentsMessagesView>
  );
};
