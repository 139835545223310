import React, { useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { IMAGES } from "./IMAGES";

const SwiperStyled = styled(Swiper)`
  position: relative;
  width: 100%;
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  .swiper-slide {
    & img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;

export const HomeBackgrounds = () => {
  return (
    <>
      <SwiperStyled
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide key={IMAGES[0].id}>
          <img src={IMAGES[0].url} alt={IMAGES[0].id} />
        </SwiperSlide>
        <SwiperSlide key={IMAGES[1].id}>
          <img src={IMAGES[1].url} alt={IMAGES[1].id} />
        </SwiperSlide>
      </SwiperStyled>
    </>
  );
};
