import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBwmfAOO1QYHMXQLmcy51PZ8MMvd9Gw7EI",
  authDomain: "drory-cc880.firebaseapp.com",
  projectId: "drory-cc880",
  storageBucket: "drory-cc880.appspot.com",
  messagingSenderId: "815490846705",
  appId: "1:815490846705:web:4d05c2e316ee7275097e50",
  measurementId: "G-XE0NY33PQ4",
};

export const app = initializeApp(firebaseConfig);


initializeAppCheck(app, {
  // @ts-ignore
  provider: new ReCaptchaV3Provider("6LciTTQgAAAAAJukeFiZR05-gsz_1FIMkgU4c9Zx"),
  isTokenAutoRefreshEnabled: true,
});

const analytics = getAnalytics(app);
logEvent(analytics, "page_view");
logEvent(analytics, "screen_view");
logEvent(analytics, "login");
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
