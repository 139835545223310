import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import { CloseButton, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { AdminColors } from "../../_Utils/colors";
import { AdminContext, ModalInterface } from "../../AdminContainer";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../backend/firebase";
import { LoaderInner } from "../../_Utils/LoaderInner";

export const DeleteModalView = styled.div`
  z-index: 1301;
`;

const ModalStyled = styled(Modal)`
  z-index: 1301;
  .modal-content {
    background-color: ${AdminColors.BG_WHITE};
    text-align: center;
    box-shadow: 0 0 15px 0 #595959;
    width: 80%;
    border-radius: 10px;
  }

  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding-top: 50px;
    padding-bottom: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      color: #535353;
      font-size: 18px;
    }

    h4 {
      color: #363636;
      font-family: hebrewRegular;
      font-size: 30px;
    }

    svg {
      color: ${AdminColors.RED_BG};
      font-size: 55px;
      padding-bottom: 20px;
    }
  }

  .modal-footer {
    background-color: ${AdminColors.RED_BG};
    justify-content: center;
    padding: 0.2rem;
  }

  .btn-close-white {
    filter: none !important;
    opacity: 1;
    transform: scale(1.1);
  }
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1301 !important;
`;

const DeleteButtonStyled = styled.a`
  color: #fff;
  font-size: 25px;
  font-family: hebrewExtraBold;
`;

interface Props {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<ModalInterface>>;
  deleteInfo: any;
}

export const DeleteModal = (props: Props) => {
  const _AdminContext = useContext(AdminContext);

  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);

  const handleClose = () => {
    props.setModalState({ show: false });
  };

  const handleDelete = async () => {
    setShowInnerLoader(true);

    await httpsCallable(
      functions,
      "deleteAppointmentFunction"
    )({
      barberName: props.deleteInfo.barberName,
      date: props.deleteInfo.date,
      appointmentHour: props.deleteInfo.appointmentHour,
      hourBeautified: props.deleteInfo.hourBeautified,
      bookUserID: props.deleteInfo.bookUserID,
      phoneNumber: props.deleteInfo.phoneNumber,
    })
      .then(() => {
        setShowInnerLoader(false);
        _AdminContext.setDeleteModalState({ show: false, deleteInfo: {} });
        _AdminContext.setSuccessModalState({
          show: true,
          text: "התור נמחק בהצלחה",
        });
      })
      .catch((e) => {
        setShowInnerLoader(false);
        _AdminContext.setDeleteModalState({ show: false });
        _AdminContext.setErrorModalState({
          show: true,
          text: "קרתה תקלה בעת המחיקה, נסה שוב",
        });
      });
  };

  return (
    <DeleteModalView>
      <ModalStyled
        show={props.modalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CloseButtonStyled variant={"white"} onClick={handleClose} />
        <Modal.Body>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          <h4>אזהרה</h4>
          <p>האם תרצה למחוק את התור?</p>
        </Modal.Body>
        <Modal.Footer>
          <DeleteButtonStyled onClick={handleDelete}>מחק</DeleteButtonStyled>
        </Modal.Footer>
        {showInnerLoader ? <LoaderInner /> : null}
      </ModalStyled>
    </DeleteModalView>
  );
};
