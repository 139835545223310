import React from "react";
import styled from "styled-components";

const MaintananceModeView = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 9;
  display: grid;
  place-items: center;
  text-align: center;

  h3 {
    color: #fff;
  }
`;

export const MaintananceMode = () => {
  return (
    <MaintananceModeView>
      <div>
        <h3>
          האתר מבצע שדרוג, כרגע לא ניתן להזמין או לבטל תורים. אם יש משהו דחוף
          דברו איתי
        </h3>
        <br />
        <h3>האתר יחזור לשימוש בזמן הקרוב</h3>
      </div>
    </MaintananceModeView>
  );
};
