import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { ModalInterface } from "../Pages/AdminPage/AdminContainer";
import InfoIcon from "@mui/icons-material/Info";
import { AdminColors } from "../Pages/AdminPage/_Utils/colors";
import Button from "@mui/material/Button";

const InformativeModalView = styled.div`
  z-index: 10;
`;

const ModalStyled = styled(Modal)`
  .modal-content {
    background-color: ${AdminColors.BG_WHITE};
    text-align: center;
    box-shadow: 0 0 15px 0 #6c6c6c;
    width: 80%;
    border-radius: 10px;
  }

  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 40px 0;
    background-color: #a8a8a8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    svg {
      color: ${AdminColors.BG_WHITE};
      font-size: 60px;
    }
  }

  .modal-footer {
    position: relative;
    justify-content: center;

    p {
      color: #535353;
      font-size: 18px;
    }

    h4 {
      color: #363636;
      font-family: hebrewBold;
      font-size: 30px;
    }
  }
`;

const FooterContainer = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  grid-auto-rows: 20px 10px auto;
  padding-bottom: 5px;
`;

const CloseTextButton = styled(Button)`
  color: #fff !important;
  background-color: ${AdminColors.NAV_BLUE} !important;
  padding: 2.5px 20px !important;
  border-radius: 25px !important;
  font-size: 20px !important;
  font-family: hebrewLight !important;
`;

interface Props {
  modalState: ModalInterface;
  setModalState: Dispatch<SetStateAction<ModalInterface>>;
}

export const InformativeModal = (props: Props) => {
  return (
    <InformativeModalView>
      <ModalStyled
        show={props.modalState.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <InfoIcon />
        </Modal.Body>
        <Modal.Footer>
          <FooterContainer>
            <h4>הודעה!</h4>
            <br />
            <p>{props.modalState.text ?? "הודעה"}</p>
            <CloseTextButton
              onClick={() => props.setModalState({ show: false })}
            >
              סגור
            </CloseTextButton>
          </FooterContainer>
        </Modal.Footer>
      </ModalStyled>
    </InformativeModalView>
  );
};
