export const IMAGES = [
  {
    id: "bg1",
    url: "https://firebasestorage.googleapis.com/v0/b/drory-cc880.appspot.com/o/newbg1.webp?alt=media&token=18fb6994-66be-426a-98a1-2811a4e08fff",
  },
  {
    id: "bg2",
    url: "https://firebasestorage.googleapis.com/v0/b/drory-cc880.appspot.com/o/newbg2.jpeg?alt=media&token=2024eaec-939c-4caa-b093-6d791a430ab9",
  },
  {
    id: "shape",
    url: "https://firebasestorage.googleapis.com/v0/b/drory-cc880.appspot.com/o/shape1.webp?alt=media&token=dd6e668d-c8d0-4395-b6ad-78c827528f96",
  },
];
