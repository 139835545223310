import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppMainContext, MainContextInterface } from "../../App";
import Button from "@mui/material/Button";
import Div100vh from "react-div-100vh";
import "swiper/css";
import "swiper/css/pagination";
import downIcon from "../../media/icons/downIcon.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebookF,
  faWhatsapp,
  faWaze,
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { HaircutSwiper } from "../Utils/HaircutSwiper";
import { HomeBackgrounds } from "../Utils/HomeBackgrounds";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { IMAGES } from "../Utils/IMAGES";
import { CoolLine } from "../Utils/CoolLine";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FastSignImage from "../../media/icons/arrow-main.png";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import bg1 from "../../media/backgrounds/bg3.webp";
import bg2 from "../../media/backgrounds/bg4.webp";
import underline from "../../media/gallery/artworks/underline.png";
import art1 from "../../media/gallery/artworks/art1.webp";
import gridArt1 from "../../media/gallery/artworks/1.webp";
import gridArt2 from "../../media/gallery/artworks/2.webp";
import gridArt3 from "../../media/gallery/artworks/3.webp";
import gridArt4 from "../../media/gallery/artworks/4.webp";
import gallery15 from "../../media/gallery/gallery15.webp";
import gallery10 from "../../media/gallery/gallery10.webp";
import gallery13 from "../../media/gallery/gallery13.webp";
import gallery12 from "../../media/gallery/gallery12.webp";
import gallery9 from "../../media/gallery/gallery9.webp";
import gallery7 from "../../media/gallery/gallery7.webp";
import logoWhite from "../../media/logoWhite.webp";
import { DateTime } from "luxon";

// Styled-Components START
const HomeContainerStyled = styled(Div100vh)`
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  place-items: center;
  text-align: center;
  color: #fff;
`;

const ButtonDivStyled = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 3;

  Button {
    font-size: 30px;
    background-color: #000000ab;
    color: #fff;
    border: none;
    box-shadow: 0px 0px 5px 0px #404040;
    font-family: hebrewBold;
    white-space: nowrap;
    padding: 10px 20px;
  }
`;

const OverflowImageStyled = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;

  img {
    width: 100%;
    transform: scale(1.2);
  }
`;

const HomeTopHeader = styled.div`
  position: relative;
  transform: rotate(-5deg);
  margin-bottom: 100px;
  margin-top: 50px;
  z-index: 3;

  a {
    font-size: 40px;
    color: #fff;
    font-family: HeyAugust;
    text-shadow: 0px 0px 2px #fff, -5px 5px 0px #3b82e096,
      -10px 10px 0px #3b82e079, -15px 15px 0px #3b82e025;
    animation: color-change 4s infinite;
    display: block;
    line-height: 1;
  }
`;

const AnimatedDownIcon = styled.div`
  position: relative;
  margin: 25px 0;

  img {
    height: 40px;
    animation: floatingAnim 1.5s ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const AnimatedDownTop = styled.div`
  position: absolute;
  bottom: 5%;
  z-index: 3;

  img {
    height: 40px;
    animation: floatingAnim 1.5s ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const FollowText = styled.div`
  position: relative;
  margin: 25px 0;

  a {
    color: #fff;
    font-size: 40px;
    font-family: HeyAugust;
    text-shadow: 0px 0px 20px rgb(242, 255, 126), 2.5px 2.5px 0px #eb452b,
      5px 5px 0px #efa032, 7.5px 7.5px 0px #46b59b, 12.5px 12.5px 0px #017e7f,
      12.5px 12.5px 10px rgb(242, 255, 126);
  }
`;

const SocialContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  margin: 50px 50px 0 50px;
  height: 200px;
  div {
    position: absolute;
    height: 135px;
    width: 135px;
    background-color: #fff;
    border-radius: 25% 15%;
    transition: all 0.5s ease-in-out;
    box-shadow: 5px 5px 0 0 #fff, 5px 5px 5px 0px rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:first-child {
      transform: rotate(0) skew(0) scale(0.8) translate(75px, -20px);
      background-color: rgb(231, 133, 54);
    }
    &:nth-child(2) {
      transform: rotate(0) skew(0) scale(0.8) translate(-75px, 20px);
      background-color: rgb(177, 66, 211);
    }
    &:nth-child(3) {
      transform: rotate(0) skew(0) scale(0.8) translate(-75px, 170px);
      background-color: rgb(66, 113, 243);
    }
    &:last-child {
      transform: rotate(0) skew(0) scale(0.8) translate(75px, 130px);
      background-color: rgb(24, 190, 38);
    }
  }

  .svg-inline--fa {
    color: rgb(255, 255, 255);
    font-size: 80px;
  }

  a {
    color: #fff;
    font-family: hebrewLight;
    font-size: 16px;
  }
`;

const ContactHeader = styled.div`
  position: relative;
  margin-top: 150px;
  z-index: 2;

  a {
    font-size: 40px;
    font-family: hebrewExtraBold;
    text-shadow: 0px 0px 6px #6897bd;
    background: linear-gradient(
        to left,
        #35a3fd 0,
        #ffffff,
        #35a3fd,
        #ffffff,
        #35a3fd 100%
      )
      0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 6s infinite linear;
    animation-fill-mode: backwards;
    -webkit-text-size-adjust: none;
  }
`;

const BottomDiv = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
  background-color: #35a3fd;
  margin-top: -50px;
  img {
    position: relative;
    width: 100%;
    top: -20px;
    transform: rotate(180deg);
  }
  div {
    display: block;
    white-space: nowrap;
    padding: 5px 15px;
    height: max-content;
    background-color: #000000;
    border-radius: 10px 20px;
    box-shadow: 0px 0px 5px 0px #000000;
    margin: 10px 40px;
    text-align: right;
    a {
      font-size: 20px;
      color: #fff;
      font-family: hebrewExtraBold;
      white-space: nowrap;
      padding-right: 10px;
      position: relative;
      bottom: 5px;
    }
    .svg-inline--fa {
      color: #fff;
      font-size: 30px;
    }
  }
`;

const FastBookingContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 10;

  svg {
    transform: scale(2);
    animation: tilt-anim 3s ease infinite;
  }
`;

const BoltSvgContainer = styled.div`
  padding: 20px;
  background-color: #3882ffb8;
  box-shadow: 0px 0px 15px 0px #4b8eff;
  border-radius: 50%;
`;

const FastSignContainer = styled.div`
  position: fixed;
  bottom: 35px;
  right: 85px;
  animation: float 3s infinite ease-in-out;
  transform: rotate(10deg);
  transition: opacity 0.5s ease;
  img {
    width: 175px;
  }

  a {
    position: absolute;
    top: 45%;
    right: 55%;
    transform: translate(50%, -50%);
    color: #fff;
    font-family: hebrewExtraBold;
    white-space: nowrap;
    font-size: 14px;
    text-shadow: -2px 2px 4px rgb(228, 225, 38);
  }
`;

const BigContainer = styled.div`
  position: relative;
  height: max-content;
  width: 100%;
  background: linear-gradient(180deg, #000 10%, #101010 50%);
  z-index: 1;
  box-shadow: 0px 1px 40px 2px #000000;
  padding-bottom: 20px;
  overflow-x: hidden;
`;

const SwiperSliderBig = styled.div`
  width: 100%;
  height: 100%;
  .swiper-slide {
    width: 100%;
    height: 100%;
    img {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      object-fit: cover;
      width: 100%;
    }
  }
`;

const SplitWrapper = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 20px;
  background-color: #cab3968f;
  z-index: 3;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 10px;
    background-color: rgba(180, 180, 180, 0.5);
  }
`;

const BigBookButton = styled.div`
  position: absolute;
  top: 15%;
  right: 50%;
  transform: translateX(50%);
  z-index: 9999;

  Button {
    font-size: 30px;
    background-color: #000000ab;
    color: #fff;
    border: none;
    box-shadow: 0px 0px 5px 0px #404040;
    font-family: hebrewBold;
    white-space: nowrap;
    padding: 10px 20px;
  }
`;

// Styled-Components END

const HomeContainer = () => {
  const _AppMainContext: MainContextInterface | undefined =
    useContext(AppMainContext);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  imageLoaded ? _AppMainContext?.setLoading(false) : null;

  const listenToScroll = () => {
    const heightToHideFrom = 400;
    const homeContainerElement = document.querySelector("#HomeContainerId");
    // @ts-ignore
    const winScroll = homeContainerElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    _AppMainContext?.setCurrentPage("Home");

    const yesterday = DateTime.now().setZone("Israel").toFormat("dd-MM-yyyy");

    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        loadImg.onload = () => resolve(image.url);
        loadImg.onerror = (err) => reject(err);
      });
    };
    Promise.all(IMAGES.map((image) => loadImage(image))).then(() =>
      setImageLoaded(true)
    );

    window.addEventListener("scroll", listenToScroll, true);
    return () => window.removeEventListener("scroll", listenToScroll, true);
  }, []);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return (
    <HomeContainerStyled id="HomeContainerId">
      {windowDimenion.winWidth < 700 ? (
        <>
          {/*<FastBookingContainer>*/}
          {/*  <Link*/}
          {/*    to={{*/}
          {/*      pathname: "/book",*/}
          {/*      hash: "fast",*/}
          {/*    }}*/}
          {/*    style={{ color: "#fff" }}*/}
          {/*  >*/}
          {/*    <BoltSvgContainer>*/}
          {/*      <FlashOnIcon />*/}
          {/*    </BoltSvgContainer>*/}
          {/*  </Link>*/}
          {/*  {isVisible ? (*/}
          {/*    <FastSignContainer>*/}
          {/*      <img src={FastSignImage} alt={"Sign Image"} />*/}
          {/*      <a>תורים מהירים</a>*/}
          {/*    </FastSignContainer>*/}
          {/*  ) : null}*/}
          {/*</FastBookingContainer>*/}
          <HomeBackgrounds />
          <ButtonDivStyled>
            {imageLoaded ? (
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <Link to="/book">
                  <Button variant="outlined">לקביעת תור 💇🏼</Button>
                </Link>
              </AnimationOnScroll>
            ) : null}
          </ButtonDivStyled>
          <OverflowImageStyled>
            <img src={IMAGES[2].url} alt={"Overflow Image"} />
          </OverflowImageStyled>
          <AnimatedDownTop>
            <img src={downIcon} alt={"donwIcon"} />
          </AnimatedDownTop>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            scrollableParentSelector={"#HomeContainerId"}
          >
            <HomeTopHeader>
              <a>SOCIAL</a>
              <a>SHOWCASE</a>
            </HomeTopHeader>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            scrollableParentSelector={"#HomeContainerId"}
          >
            <HaircutSwiper />
          </AnimationOnScroll>
          <CoolLine />
          <CoolLine
            transform={"scale(1.5) rotate(-20deg)"}
            bottom={"-120vh"}
            color={"drop-shadow(0 0 20px #28ceff)"}
          />
          <AnimationOnScroll
            animateIn="animate__fadeInDown"
            scrollableParentSelector={"#HomeContainerId"}
          >
            <AnimatedDownIcon>
              <img src={downIcon} alt={"donwIcon"} />
            </AnimatedDownIcon>
            <FollowText>
              <a>Follow Me</a>
            </FollowText>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            scrollableParentSelector={"#HomeContainerId"}
          >
            <SocialContainer>
              <div
                onClick={() =>
                  window.open("https://www.instagram.com/yarindrory/", "blank")
                }
              >
                <FontAwesomeIcon icon={faInstagram} />
                <a>instagram.com</a>
              </div>
              <div
                onClick={() =>
                  window.open("https://www.tiktok.com/@yarin_drory", "_blank")
                }
              >
                <FontAwesomeIcon icon={faTiktok} />
                <a>tiktok.com</a>
              </div>
              <div
                onClick={() =>
                  window.open("https://www.facebook.com/yarin.drory", "_blank")
                }
              >
                <FontAwesomeIcon icon={faFacebookF} />
                <a>facebook.com</a>
              </div>
              <div
                onClick={() =>
                  window.open("https://wa.me/+972524597306", "_blank")
                }
              >
                <FontAwesomeIcon icon={faWhatsapp} />
                <a>whatsapp.com</a>
              </div>
            </SocialContainer>
          </AnimationOnScroll>
          <ContactHeader>
            <a>דרכי הגעה</a>
          </ContactHeader>
          <BottomDiv>
            <img src={IMAGES[2].url} alt={"Shape"} />
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <a>שלומי - רחוב ניצן בית 23-ב</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faWaze} />
              <a href="https://waze.com/ul/hsvbupyw93">נווט באמצעות Waze</a>
            </div>
          </BottomDiv>
        </>
      ) : (
        <BigContainer>
          <SwiperSliderBig>
            <BigBookButton>
              <Link to="/book">
                <Button variant="outlined">לקביעת תור 💇🏼</Button>
              </Link>
            </BigBookButton>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={bg1} alt={"bg1"} className="bgImage" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={bg2} alt={"bg2"} className="bgImage" />
              </SwiperSlide>
            </Swiper>
          </SwiperSliderBig>
          <SplitWrapper />
          <div className="mainGrid">
            <div className="topGrid">
              <a>ברוכים הבאים למספרה של ירין דרורי</a>
              <br />
              <img src={underline} />
            </div>
            <div className="artRight">
              <img src={art1} />
            </div>
            <div className="rightPara">
              <a>המספרה של ירין דרורי</a>
              <br />
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <p>הגעתם למספרה מס'1 בכל הצפון</p>
            </div>
            <div className="artLeft">
              <div className="artLeft-grid">
                <img id="art1" src={gridArt1} />
                <img id="art2" src={gridArt2} />
                <img id="art3" src={gridArt3} />
                <img id="art4" src={gridArt4} />
              </div>
            </div>
            <div className="leftPara">
              <a>מספרת שיער לגברים</a>
              <br />
              <p>לחווית התספות הטובה ביותר</p>
            </div>
          </div>
          <div className="footerBig">
            <div className="footerGrid">
              <div className="gridRight">
                <img src={logoWhite} />
                <br />
                <a>שלומי - רחוב ניצן בית 32-ב</a>
                <br />
                <a>ירין דרורי - 052-4597306</a>
                <br />
                <a>עילי מלכה - 052-3279663</a>
                <br />
                <a>נאור בליילתי - 052-7042922</a>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/yarindrory/"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@yarin_drory"
                      rel="noreferrer"
                    >
                      <i className="fab fa-tiktok"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/yarin.drory"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://wa.me/+972524597306"
                      rel="noreferrer"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="gridCenter">
                <a id="gridCenterHeader">תפריט</a>
                <ul>
                  <li>
                    <Link to="/">ראשי</Link>
                  </li>
                  <li>
                    <Link to="/book">הזמנת תורים</Link>
                  </li>
                  <li>
                    <Link to="/delete">מחיקת תור</Link>
                  </li>
                </ul>
              </div>
              <div className="gridLeft">
                <div className="gridLeftTxt">
                  <a>גלריה</a>
                </div>
                <div className="artworkGrid">
                  <div className="art1">
                    <img src={gallery15} />
                  </div>
                  <div className="art2">
                    <img src={gallery10} />
                  </div>
                  <div className="art3">
                    <img src={gallery13} />
                  </div>
                  <div className="art4">
                    <img src={gallery12} />
                  </div>
                  <div className="art5">
                    <img src={gallery9} />
                  </div>
                  <div className="art6">
                    <img src={gallery7} />
                  </div>
                </div>
              </div>
              <div className="gridBottom">
                <a
                  href="https://dorfurman.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Developed by dorfurman.com
                </a>
              </div>
            </div>
          </div>
        </BigContainer>
      )}
    </HomeContainerStyled>
  );
};

export default HomeContainer;
