import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AdminColors } from "../_Utils/colors";
import { db } from "../../../../backend/firebase";
import { AdminContext } from "../AdminContainer";
import CountUp from "react-countup";
import {
  englishDays,
  hebrewMonths,
  UseDateHooks,
} from "../SecondTopBar/SecondTopBar";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { OpenHours_Item } from "./_Parts/OpenHours_Item";
import { LoaderInner } from "../_Utils/LoaderInner";

const HomePageView = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  gap: 40px;
  margin-top: 50px;
  height: max-content;
`;

const TopCircleSection = styled.div`
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  gap: 20px;
`;

const DuoCircles = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 40px;
  place-items: center;
`;

const CircleView = styled.div`
  display: grid;
  place-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 5px #d8d8d8;
  background-color: #f9faff;
  text-align: center;
`;

const Counter = styled(CountUp)`
  color: ${AdminColors.NAV_BLUE};
  font-size: 28px;
  font-family: hebrewBold;
`;

const CounterHeadline = styled.a`
  color: #3d3d3d;
  font-size: 14px;
  white-space: nowrap;
  font-family: hebrewLight;
`;

const BottomSection = styled.div`
  position: relative;
`;

const OpenHoursContainer = styled.div`
  position: relative;
  margin-top: 40px;
  display: grid;
  place-items: center;
  padding: 0 50px;
  padding-bottom: 70px;
`;

const OpenHoursHeader = styled.h3`
  font-size: 25px;
  font-family: hebrewRegular;
`;

interface ChartDataInterface {
  name: string;
  value: number;
}

export const HomePage = () => {
  const [dayCnt, setDayCnt] = useState<number>(0);
  const [monthCnt, setMonthCnt] = useState<number>(0);
  const [yearCnt, setYearCnt] = useState<number>(0);
  const [noBooksCnt, setNoBooksCnt] = useState<number>(0);
  const [chartData, setChartData] = useState<ChartDataInterface[]>([]);
  const [openHoursData, setOpenHoursData] = useState<any>([]);
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);

  const _AdminContext = useContext(AdminContext);

  useEffect(() => {
    _AdminContext.setIsLoading(true);
    try {
      onSnapshot(
        doc(
          db,
          `Barbers/${_AdminContext.adminName}/Dates/${
            UseDateHooks().fullFirebaseDate
          }`
        ),
        (doc) => {
          if (doc.data()) {
            setDayCnt(Object.keys(doc.data()!).length);
          }
        }
      );
      onSnapshot(
        doc(
          db,
          `Barbers/${_AdminContext.adminName}/Data/Booked/${
            UseDateHooks().yearNumber
          }/Data`
        ),
        (snapshot) =>
          setMonthCnt(snapshot.data()?.Months[UseDateHooks().monthWithoutZero])
      );
      onSnapshot(
        doc(
          db,
          `Barbers/${_AdminContext.adminName}/Data/Booked/${
            UseDateHooks().yearNumber
          }/Data`
        ),
        (snapshot) => setYearCnt(snapshot.data()?.Total)
      );
      onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data/No-Bookings`),
        (snapshot) => setNoBooksCnt(snapshot.data()?.counter)
      );
      onSnapshot(
        doc(db, `Barbers/${_AdminContext.adminName}/Data/open-hours`),
        (snapshot) => setOpenHoursData(snapshot.data())
      );
      onSnapshot(
        doc(
          db,
          `Barbers/${_AdminContext.adminName}/Data/Booked/${
            UseDateHooks().yearNumber
          }/Data`
        ),
        (snapshot) => {
          const data: { number: string }[] = snapshot.data()?.Months;
          setChartData(
            // @ts-ignore
            Object.entries(data).map((item: [string, number]) => {
              return {
                name: hebrewMonths[parseInt(item[0]) - 1],
                value: item[1],
              };
            })
          );
        }
      );
      _AdminContext.setIsLoading(false);
    } catch (error) {
      _AdminContext.setIsLoading(false);
      _AdminContext.setErrorModalState({
        show: true,
        text: "קרתה שגיאת - נא רענן",
      });
    }
  }, []);

  return (
    <HomePageView>
      {showInnerLoader ? <LoaderInner /> : null}
      <TopCircleSection>
        <DuoCircles>
          <CircleView>
            <div>
              <Counter end={dayCnt} duration={1} />
              <br />
              <CounterHeadline>תורים להיום</CounterHeadline>
            </div>
          </CircleView>
          <CircleView>
            <div>
              <Counter end={noBooksCnt} duration={1} />
              <br />
              <CounterHeadline style={{ fontSize: "0.7em" }}>
                תורים שלא נמצאו
              </CounterHeadline>
            </div>
          </CircleView>
        </DuoCircles>
        <DuoCircles>
          <CircleView>
            <div>
              <Counter end={monthCnt} duration={1} />
              <br />
              <CounterHeadline>תורים החודש</CounterHeadline>
            </div>
          </CircleView>
          <CircleView>
            <div>
              <Counter end={yearCnt} duration={1} />
              <br />
              <CounterHeadline>תורים השנה</CounterHeadline>
            </div>
          </CircleView>
        </DuoCircles>
      </TopCircleSection>
      <BottomSection>
        {chartData.length === 12 ? (
          <ResponsiveContainer width={400} height={200}>
            <AreaChart
              data={chartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="50%"
                    stopColor={AdminColors.NAV_BLUE}
                    stopOpacity={0.8}
                  />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" reversed={true} />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="value"
                stroke={AdminColors.NAV_BLUE}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : null}
        <OpenHoursContainer>
          <OpenHoursHeader>שעות פתיחה</OpenHoursHeader>
          {openHoursData.length !== 0
            ? englishDays.map((day, i) => {
                return (
                  <OpenHours_Item
                    key={i}
                    day={day}
                    dayData={openHoursData[day]}
                    setShowInnerLoader={setShowInnerLoader}
                  />
                );
              })
            : null}
        </OpenHoursContainer>
      </BottomSection>
    </HomePageView>
  );
};
