import React, { useContext, useEffect } from "react";
import { BarLoader } from "react-spinners";
import { css } from "@emotion/react";
import styled, { keyframes } from "styled-components";
import logoImage from "../../media/logoWhite.webp";
import { AppMainContext, MainContextInterface } from "../../App";
import fadeIn from "react-animations/lib/fadeIn";
import Div100vh from "react-div-100vh";

// Animations START

const fadeInAnimation = keyframes`${fadeIn}`;

// Animation END
// Styled-Components START

const LoaderStyled = styled(Div100vh)`
  width: 100%;
  height: 100%;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 1);
  display: grid;
  place-items: center;
  z-index: 100;
  animation: 0.5s ${fadeInAnimation};
  color: #fff;

  img {
    width: 250px;
  }
`;

const LoaderBottomStyled = styled.div`
  text-align: center;
`;

const override = css`
  display: block;
  margin-top: 10px;
`;

interface Props {
  display: boolean;
}

// Styled-Components END

const Loader = (props: Props) => {
  const _AppMainContext: MainContextInterface | undefined =
    useContext(AppMainContext);

  useEffect(() => {
    _AppMainContext?.setCurrentPage("Loader");
  }, []);
  return (
    <>
      {props.display ? (
        <LoaderStyled>
          <img src={logoImage} alt={"Logo"} />
          <LoaderBottomStyled>
            <a>טוען...</a>
            <BarLoader color={"#fff"} css={override} />
          </LoaderBottomStyled>
        </LoaderStyled>
      ) : null}
    </>
  );
};

export default Loader;
