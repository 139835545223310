import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";
import { AdminColors } from "../../_Utils/colors";
import "../../../../Utils/SwipedEvents";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { AdminContext } from "../../AdminContainer";

const AppointmentItemView = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  background-color: #fff;
  color: #000;
  border-radius: 25px;
  width: 100%;
  height: max-content;
  box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;

  a,
  span {
    font-size: 20px;
    color: #000;
    font-family: hebrewRegular;
  }

  span {
    font-family: hebrewLight !important;
  }
`;

const RightSide = styled.div`
  padding: 20px;
`;

const LeftSide = styled.div`
  position: relative;
  height: 100%;
  width: 100px;
  background-color: #000;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: grid;
  grid-auto-flow: row;
`;

const HourText = styled.h4`
  color: ${AdminColors.NAV_BLUE};
  font-size: 30px;
  font-family: hebrewLight;
`;

const PhoneText = styled.a`
  font-size: 20px;
  color: #000;
  font-family: hebrewLight !important;
  text-decoration: underline !important;
`;

const ReminderDiv = styled.div`
  background-color: ${AdminColors.NAV_BLUE};
  display: grid;
  place-items: center;
  color: #fff;
  border-top-left-radius: 25px;
`;

const DeleteDiv = styled.div`
  display: grid;
  place-items: center;
  background-color: ${AdminColors.RED_BG};
  color: #fff;
  border-bottom-left-radius: 25px;
`;

interface Props {
  uid: string;
  appointmentHour: string;
  appointmentType: string;
  additions: string;
  appointmentDuration: number;
  setShowInnerLoader: Dispatch<SetStateAction<boolean>>;
  date: string;
}

export const beautifyHourHook = (hour: string): string => {
  if (hour.length === 3) return `0${hour.slice(0, 1)}:${hour.slice(1)}`;
  return `${hour.slice(0, 2)}:${hour.slice(2)}`;
};

export const Appointment_Item = (props: Props) => {
  const _AdminContext = useContext(AdminContext);
  const [clientNumber, setClientNumber] = useState<string>("");

  function UserFetch() {
    const userRef = doc(useFirestore(), `Users/${props.uid}`);
    const { status, data } = useFirestoreDocData(userRef);
    if (status === "success") {
      setTimeout(() => props.setShowInnerLoader(false), 500);
      setClientNumber(data.Phone);
      return (
        <>
          <a>שם: </a>
          <span>{data.Name}</span>
          <br />
          <a>טלפון: </a>
          <PhoneText href={`tel:${data.Phone}`}>{data.Phone}</PhoneText>
        </>
      );
    }
    return <></>;
  }

  const handleDelete = async () => {
    const hourFinal = beautifyHourHook(props.appointmentHour);

    _AdminContext.setDeleteModalState({
      show: true,
      deleteInfo: {
        barberName: _AdminContext.adminName,
        date: props.date,
        appointmentHour: props.appointmentHour,
        hourBeautified: hourFinal,
        bookUserID: props.uid,
        phoneNumber: clientNumber,
      },
    });
  };

  const handleReminder = () => {
    const text = `אהלן אח, מזכיר לך שיש לך תור היום ב ${beautifyHourHook(
      props.appointmentHour
    )}`;
    window.open(`https://wa.me/+972${clientNumber.substring(1)}?text=${text}`);
  };

  return (
    <AppointmentItemView>
      <RightSide>
        <HourText>
          {beautifyHourHook(props.appointmentHour) ?? "אין מידע"}
        </HourText>
        <UserFetch />
        <br />
        <a>סוג: </a>
        <span>{props.appointmentType ?? "אין מידע"}</span>
        <br />
        <a>תוספות: </a>
        <span>{props.additions ? "תוספת שעווה" : "ללא תוספת"}</span>
        <br />
        <a>זמן: </a>
        <span>{`${props.appointmentDuration * 5} דק׳` ?? "אין מידע"}</span>
      </RightSide>
      <LeftSide>
        <ReminderDiv onClick={handleReminder}>שלח תזכורת</ReminderDiv>
        <DeleteDiv onClick={handleDelete}>מחק תור</DeleteDiv>
      </LeftSide>
    </AppointmentItemView>
  );
};
