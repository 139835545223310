import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import { AdminContext } from "../AdminContainer";
import fadeIn from "react-animations/lib/fadeIn";
import { AdminColors } from "../_Utils/colors";
import moment from "moment";
import { doc } from "firebase/firestore";
import { Appointment_Item } from "./_Parts/Appointment_Item";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { LoaderInner } from "../_Utils/LoaderInner";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../backend/firebase";

//
registerLocale("he", he);

const fadeInAnimation = keyframes`${fadeIn}`;

const AppointmentsView = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  gap: 20px;
  padding: 0 20px;
  overflow-y: auto;
  margin-top: 20px;
  height: calc(100% - 200px);
`;

export const DateContainer = styled.div`
  .react-datepicker {
    background-color: #fff;
    border: none;
    border-radius: 25px;
    animation: 0.5s ${fadeInAnimation};
    box-shadow: 0 0 5px 0 #ebebeb, 0 5px 0 0 #ebebeb, 0 10px 5px 0 #e1e1e1;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker__day {
    color: #000;
    text-align: center;
    padding-top: 10px;
  }

  .react-datepicker__current-month {
    font-size: 18px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 3em;
    height: 3em;
    font-family: hebrewLight;
    font-size: 16px;
    border-radius: 0;
    margin: 0;
  }

  .react-datepicker__day-name {
    font-family: hebrewRegular;
  }

  .react-datepicker__month .react-datepicker__day {
    background-color: #fff;
  }

  .react-datepicker__current-month {
    margin-top: 10px;
    font-family: hebrewRegular;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__navigation {
    height: 1.5em;
    width: 1.5em;
    margin: 1.5em;
    font-size: 16px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${AdminColors.NAV_BLUE} !important;
    color: #fff;
    border-radius: 10px;
  }

  .react-datepicker__day--disabled {
    background-color: #fff !important;
    color: #000 !important;
    filter: blur(2px);
  }

  .react-datepicker__navigation--previous--disabled {
    opacity: 0.5;
  }

  .react-datepicker__navigation--previous::after {
    content: "קודם";
  }

  .react-datepicker__navigation--next::after {
    content: "הבא";
  }
`;

// Interfaces START

const AppointmentsContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  row-gap: 20px;
  width: 100%;
  height: max-content;
`;

const NoBookingsText = styled.a`
  color: #000;
  font-size: 25px;
  font-family: hebrewLight;
`;

// Interfaces END
// Hooks START

const sortAppointments = (appointmentsArray: any) => {
  return appointmentsArray.sort((a, b) => {
    if (parseInt(Object.keys(a)[0]) < parseInt(Object.keys(b)[0])) return -1;
    return 1;
  });
};

// Hooks END
// Component START

export const Appointments = () => {
  const _AdminContext = useContext(AdminContext);

  const [datePicked, setDatePicked] = useState<Date>(new Date());
  const [showInnerLoader, setShowInnerLoader] = useState<boolean>(false);

  useEffect(() => {
    _AdminContext.setIsLoading(false);
  }, []);

  function DataFetch() {
    const firebaseDate = moment(datePicked).format("DD-MM-YYYY");
    const dataRef = doc(
      useFirestore(),
      `Barbers/${_AdminContext.appointmentsAdminName}/Dates/${firebaseDate}`
    );
    const { status, data } = useFirestoreDocData(dataRef);

    if (status === "loading") setShowInnerLoader(true);
    if (data !== undefined && Object.keys(data).length > 1) {
      if (status === "success") {
        const array: Array<any> = [];
        Object.entries(data).map((item) => {
          if (item[0] !== "NO_ID_FIELD") {
            array.push({
              [item[0]]: {
                uid: item[1].uid,
                appointmentType: item[1].typeText,
                appointmentDuration: item[1].typeDuration,
                additions: item[1].addition,
              },
            });
          }
        });
        return (
          <AppointmentsContainer>
            {array.length > 0 ? (
              sortAppointments(array).map((hour) => {
                return Object.entries(hour).map((item, i) => {
                  return (
                    <Appointment_Item
                      key={i} // @ts-ignore
                      uid={item[1].uid}
                      appointmentHour={item[0]} // @ts-ignore
                      appointmentType={item[1].appointmentType} // @ts-ignore
                      additions={item[1].additions} // @ts-ignore
                      appointmentDuration={item[1].appointmentDuration}
                      setShowInnerLoader={setShowInnerLoader}
                      date={firebaseDate}
                    />
                  );
                });
              })
            ) : (
              <NoBookingsText>אין תורים</NoBookingsText>
            )}
          </AppointmentsContainer>
        );
      }
    } else if (status === "success") {
      setShowInnerLoader(false);
    }
    return <NoBookingsText>אין תורים</NoBookingsText>;
  }

  return (
    <AppointmentsView>
      <DateContainer>
        <DatePicker
          inline
          selected={datePicked}
          onChange={(date: Date) => setDatePicked(date)}
          locale={he}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(4, 5)}
          showDisabledMonthNavigation
        />
      </DateContainer>
      <DataFetch />
      {showInnerLoader ? <LoaderInner /> : null}
    </AppointmentsView>
  );
};
