import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { ModalInterface } from "../Pages/AdminPage/AdminContainer";
import { AdminColors } from "../Pages/AdminPage/_Utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";

const ErrorModalView = styled.div`
  z-index: 1301;
`;

const ModalStyled = styled(Modal)`
  z-index: 1301;
  .modal-content {
    background-color: ${AdminColors.BG_WHITE};
    text-align: center;
    box-shadow: 0 0 15px 0 #595959;
    width: 80%;
    border-radius: 10px;
  }

  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding-top: 50px;
    padding-bottom: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      color: #535353;
      font-size: 18px;
    }

    h4 {
      color: #363636;
      font-family: hebrewRegular;
      font-size: 30px;
    }

    svg {
      color: ${AdminColors.RED_BG};
      font-size: 55px;
      padding-bottom: 20px;
    }
  }

  .modal-footer {
    background-color: ${AdminColors.RED_BG};
    justify-content: center;
    padding: 0.2rem;
  }
`;

const CloseTextButton = styled.a`
  color: #fff;
  font-size: 25px;
  font-family: hebrewExtraBold;
`;

interface Props {
  errorText?: string;
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<ModalInterface>>;
}

export const ErrorModal = (props: Props) => {
  return (
    <ErrorModalView>
      <ModalStyled
        show={props.modalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          <h4>אופס...</h4>
          <p>{props.errorText ?? "אירעה שגיאה! רענן את העמוד"}</p>
        </Modal.Body>
        <Modal.Footer>
          <CloseTextButton onClick={() => props.setModalState({ show: false })}>
            סגור
          </CloseTextButton>
        </Modal.Footer>
      </ModalStyled>
    </ErrorModalView>
  );
};
