import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Appointment_type } from "./_Parts/Appointment_type";
import { AdminContext } from "../AdminContainer";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../backend/firebase";

const AppointmentsTypesView = styled.div`
  padding: 0 20px;
`;

const AppointmentsContainer = styled.div`
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  padding-bottom: 20px;
  box-shadow: 0 0 15px 0 #e7e7e7;
  border-radius: 10px;
`;

export const AppointmentsTypes = () => {
  const _AdminContext = useContext(AdminContext);
  const [typesData, setTypesData] = useState<Array<any>>([]);

  useEffect(() => {
    onSnapshot(
      doc(db, `Barbers/${_AdminContext.adminName}/Data`, "Type"),
      (snapshot) => {
        const data = snapshot.data();
        // @ts-ignore
        setTypesData(Object.entries(data).sort());
        _AdminContext.setIsLoading(false);
      }
    );
  }, []);

  return (
    <AppointmentsTypesView>
      <AppointmentsContainer id="scrollParent">
        {typesData.length > 0
          ? typesData.map((type, i) => (
              <Appointment_type
                key={i}
                haircutName={type[1][0]}
                haircutDuration={type[1][1]}
                haircutCost={type[1][2]}
                haircutIndex={i}
              />
            ))
          : null}
      </AppointmentsContainer>
    </AppointmentsTypesView>
  );
};
