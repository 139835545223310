import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { SecondTopBar } from "./SecondTopBar/SecondTopBar";
import Div100vh from "react-div-100vh";
import { AdminColors } from "./_Utils/colors";
import { AdminNavContainer } from "./AdminNav/AdminNavContainer";
import { AdminLogin } from "./_Pages/AdminLogin";
import { AdminLoader } from "./_Utils/AdminLoader";
import { useSigninCheck } from "reactfire";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../backend/firebase";
import { getAuth, signOut } from "firebase/auth";
import { ErrorModal } from "../../Utils/ErrorModal";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./_Pages/_HomePage";
import { Appointments } from "./_Pages/_Appointments";
import { AppointmentsTypes } from "./_Pages/_AppointmentsTypes";
import { AppointmentsMessages } from "./_Pages/_Messages";
import { Vacations } from "./_Pages/_Vacations";
import { Users } from "./_Pages/_Users";
import { SuccessModal } from "../../Utils/SuccessModal";
import { CreationButton } from "./_Utils/CreationButton";
import { AppointmentCreation } from "./_Utils/AppointmentCreation";
import { BreaksCreation } from "./_Utils/BreaksCreation";
import { ListModal } from "./_Utils/ListModal";
import { DeleteModal } from "./_Pages/_Parts/DeleteModal";
import { UserCreation } from "./_Utils/UserCreation";

// Styled-Components START

const AdminContainerStyled = styled.div`
  height: 100%;
  background-color: ${AdminColors.BG_WHITE};
  color: #000;
  overflow-y: scroll;
  touch-action: auto;
`;

// Styled-Components END

interface Props {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

export interface ModalInterface {
  show: boolean;
  text?: string;
  list?: string[];
  deleteInfo?: any;
}

export interface BreaksModalInterface {
  show: boolean;
  type?: "breaks-day" | "breaks-date" | "vacations";
}

export interface AdminContextInterface {
  adminName: string;
  appointmentsAdminName: string;
  errorModalState: ModalInterface;
  isLoading: boolean;
  setErrorModalState: Dispatch<SetStateAction<ModalInterface>>;
  setSuccessModalState: Dispatch<SetStateAction<ModalInterface>>;
  setListModalState: Dispatch<SetStateAction<ModalInterface>>;
  setAppointmentCreationState: Dispatch<SetStateAction<boolean>>;
  setUserCreationState: Dispatch<SetStateAction<boolean>>;
  setBreaksCreationState: Dispatch<SetStateAction<BreaksModalInterface>>;
  setDeleteModalState: Dispatch<SetStateAction<ModalInterface>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setAppointmentsAdminName: Dispatch<SetStateAction<string>>;
}

export const AdminContext = createContext<AdminContextInterface>({
  adminName: "",
  appointmentsAdminName: "",
  errorModalState: { show: false },
  isLoading: false,
  setErrorModalState: () => undefined,
  setSuccessModalState: () => undefined,
  setListModalState: () => undefined,
  setAppointmentCreationState: () => undefined,
  setUserCreationState: () => undefined,
  setBreaksCreationState: () => undefined,
  setDeleteModalState: () => undefined,
  setIsLoading: () => undefined,
  setAppointmentsAdminName: () => undefined,
});

export const AdminContainer = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<ModalInterface>({
    show: false,
  });
  const [successModalState, setSuccessModalState] = useState<ModalInterface>({
    show: false,
  });
  const [listModalState, setListModalState] = useState<ModalInterface>({
    show: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState<ModalInterface>({
    show: false,
  });
  const [appointmentCreationState, setAppointmentCreationState] =
    useState<boolean>(false);
  const [userCreationState, setUserCreationState] = useState<boolean>(false);
  const [breaksCreationState, setBreaksCreationState] =
    useState<BreaksModalInterface>({ show: false });
  const [adminName, setAdminName] = useState<string>("");
  const [appointmentsAdminName, setAppointmentsAdminName] =
    useState<string>("");

  const { status, data: signInCheckResult } = useSigninCheck();

  const adminCheckHook = async (uid: string) => {
    const docRef = doc(db, "Admins", uid);
    await getDoc(docRef)
      .then((result) => {
        if (result) {
          setAdminName(result.data()?.name);
          setAppointmentsAdminName(result.data()?.name);
          setIsLogged(true);
          setIsAdmin(true);
        }
      })
      .catch(() => {
        setErrorModalState({ show: true, text: "אין לך הרשאות" });
        signOutHook();
      });
  };

  const signOutHook = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setIsLogged(false);
        setIsAdmin(false);
      })
      .catch(() => {
        setErrorModalState({ show: true });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (status === "success") {
      if (signInCheckResult.signedIn) {
        adminCheckHook(signInCheckResult.user.uid);
      } else {
        setIsLoading(false);
      }
    }
  }, [status, signInCheckResult, isLogged]);

  useEffect(() => {
    props.setCurrentPage("Admin");
    props.setLoading(false);
  }, []);

  return (
    <AdminContext.Provider
      value={{
        adminName,
        appointmentsAdminName: appointmentsAdminName,
        errorModalState: errorModalState,
        isLoading: isLoading,
        setErrorModalState: setErrorModalState,
        setSuccessModalState: setSuccessModalState,
        setListModalState: setListModalState,
        setAppointmentCreationState: setAppointmentCreationState,
        setUserCreationState: setUserCreationState,
        setBreaksCreationState: setBreaksCreationState,
        setDeleteModalState: setShowDeleteModal,
        setIsLoading: setIsLoading,
        setAppointmentsAdminName: setAppointmentsAdminName,
      }}
    >
      {isLoading ? <AdminLoader /> : null}
      {!isLogged ? (
        <AdminLogin setIsLogged={setIsLogged} setIsLoading={setIsLoading} />
      ) : null}
      {isAdmin ? (
        <Div100vh>
          <AdminNavContainer />
          <CreationButton />
          {appointmentCreationState ? (
            <AppointmentCreation
              modalState={appointmentCreationState}
              setModalState={setAppointmentCreationState}
            />
          ) : null}
          {breaksCreationState ? (
            <BreaksCreation
              modalState={breaksCreationState}
              setModalState={setBreaksCreationState}
            />
          ) : null}
          {userCreationState ? (
            <UserCreation
              modalState={userCreationState}
              setModalState={setUserCreationState}
            />
          ) : null}
          <AdminContainerStyled>
            <SecondTopBar
              setIsLogged={setIsLogged}
              setIsAdmin={setIsAdmin}
              signOutHook={signOutHook}
            />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route
                path="/appointments-types"
                element={<AppointmentsTypes />}
              />
              <Route
                path="/system-messages"
                element={<AppointmentsMessages />}
              />
              <Route path="/vacations" element={<Vacations />} />
              <Route path="/users" element={<Users />} />
            </Routes>
          </AdminContainerStyled>
        </Div100vh>
      ) : null}
      <ErrorModal
        modalState={errorModalState.show}
        errorText={errorModalState?.text}
        setModalState={setErrorModalState}
      />
      <SuccessModal
        modalState={successModalState.show}
        successText={successModalState.text}
        setModalState={setSuccessModalState}
      />
      <ListModal
        modalState={listModalState.show}
        dates={listModalState.list}
        setModalState={setListModalState}
      />
      <DeleteModal
        modalState={showDeleteModal.show}
        deleteInfo={showDeleteModal.deleteInfo}
        setModalState={setShowDeleteModal}
      />
    </AdminContext.Provider>
  );
};
